import { invalidateActivation } from './activated';
import { receiveBadges } from './badges';
import { receiveSessionInfo } from './login';
export const REQUEST_DIFF = 'REQUEST_DIFF';
function requestDiff() {
    return {
        type: REQUEST_DIFF,
    };
}

export const RECEIVE_DIFF = 'RECEIVE_DIFF';
function receiveDiff(diff) {
    return {
        type: RECEIVE_DIFF,
        diff: Date.now() / 1000 - diff,
        receivedAt: Date.now(),
    };
}

export const INVALIDATE_DIFF = 'INVALIDATE_DIFF';
export function invalidateDiff() {
    return {
        type: INVALIDATE_DIFF,
    };
}

export function fetchDiffAndBadges(token) {
    return dispatch => {
        dispatch(requestDiff());
        return fetch(`${process.env.REACT_APP_API_BASE_URL}/mobileapi/get_badges?patrulje_token=${token}&_=${new Date().getTime()}`)
            .then(res => {
                return res.json();
            })
            .then(json => {
                if (json.status === 'NO_PATRULJE') {
                    dispatch(invalidateActivation());
                } else {
                    dispatch(receiveBadges(json.badges));
                    dispatch(receiveDiff(json.sessionInfo.currentTime.epoch));
                    dispatch(receiveSessionInfo(json.sessionInfo));
                }
            });
    };
}
