export const REQUEST_TOKEN = 'REQUEST_TOKEN';
function requestToken() {
    return {
        type: REQUEST_TOKEN,
    };
}

export const RECEIVE_TOKEN = 'RECEIVE_TOKEN';
function receiveToken(token) {
    return {
        type: RECEIVE_TOKEN,
        token: token,
        receivedAt: Date.now(),
    };
}

export const INVALIDATE_TOKEN = 'INVALIDATE_TOKEN';
export function invalidateToken() {
    return {
        type: INVALIDATE_TOKEN,
    };
}

export const SET_LOGINERROR = 'SET_LOGINERROR';
export function setLoginError(loginError) {
    return {
        type: SET_LOGINERROR,
        loginError: loginError,
        receivedAt: Date.now(),
    };
}

export const RECEIVE_SESSIONINFO = 'RECEIVE_SESSIONINFO';
export function receiveSessionInfo(sessionInfo) {
    return {
        type: RECEIVE_SESSIONINFO,
        sessionInfo: sessionInfo,
        receivedAt: Date.now(),
    };
}

export const INVALIDATE_SESSIONINFO = 'INVALIDATE_SESSIONINFO';
export function invalidateSessionInfo() {
    return {
        type: INVALIDATE_SESSIONINFO,
    };
}

function fetchToken(telefonnummer, startkode) {
    return dispatch => {
        dispatch(requestToken());
        return fetch(
            `${process.env.REACT_APP_API_BASE_URL}/mobileapi/login_patrulje?telefonnummer=${telefonnummer}&startkode=${startkode}&_=${new Date().getTime()}`, {
            method: 'POST'
        }
        )
            .then(res => {
                return res.json();
            })
            .then(json => {
                if (json.status === 'NO_PATRULJE') {
                    dispatch({ type: 'LOGOUT' });
                } else if (json.status === 'INVALID_STARTKODE') {
                    dispatch(setLoginError('Startkoden er forkert - Prøv igen'));
                } else {
                    dispatch(receiveToken(json.token));
                    dispatch(receiveSessionInfo(json.sessionInfo));
                }
            });
    };
}

export function resendActivationcode(token) {
    return dispatch => {
        return fetch(
            `${process.env.REACT_APP_API_BASE_URL}/mobileapi/resend_activation_key?patrulje_token=${token}&_=${new Date().getTime()}`, {
            method: 'POST'
        }
        )
            .then(res => {
                return res.json();
            })
            .then(json => { });
    };
}

function shouldFetchToken(state) {
    const token = state.token;
    if (!token) {
        return true;
    } else if (token.isFetching) {
        return false;
    } else {
        return token.didInvalidate;
    }
}

export function fetchTokenIfNeeded(telefonnummer, startkode) {
    // Note that the function also receives getState()
    // which lets you choose what to dispatch next.

    // This is useful for avoiding a network request if
    // a cached value is already available.

    return (dispatch, getState) => {
        if (shouldFetchToken(getState())) {
            // Dispatch a thunk from thunk!
            return dispatch(fetchToken(telefonnummer, startkode));
        } else {
            // Let the calling code know there's nothing to wait for.
            return Promise.resolve();
        }
    };
}
