import { RECEIVE_TOKEN, INVALIDATE_TOKEN } from '../actions/login';
import { INVALIDATE_ACTIVATION } from '../actions/activated';
function reducer(state = null, action) {
    switch (action.type) {
        case RECEIVE_TOKEN:
            return action.token;
        case INVALIDATE_TOKEN:
            return null;
        case INVALIDATE_ACTIVATION:
            return null;
        default:
            return state;
    }
}

export default reducer;
