import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import Paper from 'material-ui/Paper';
import Grid from 'material-ui/Grid';
import { AppBar, Tabs, Tab, Typography, CircularProgress, Chip, List, Divider, ListItem, Avatar, ListItemIcon, ListItemText } from 'material-ui';
import { connect } from 'react-redux';
import { fetchOpgaver } from './actions/opgaver';
import { setOpgaverTab } from './actions/defaultTab';

function TabContainer(props) {
    return <Typography component="div">{props.children}</Typography>;
}

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    tabRoot: {
        flexGrow: 1,
        margin: theme.spacing.unit * 2,
    },
    title: {
        marginBottom: theme.spacing.unit
    },
    videreButton: {
        marginTop: theme.spacing.unit * 2,
    },
    aktiverButton: {
        marginTop: theme.spacing.unit * 2,
        backgroundColor: theme.palette.success.main,
    },
    gensendButton: {
        marginTop: theme.spacing.unit * 2,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.text.primary,
    },
    annullerButton: {
        marginTop: theme.spacing.unit * 2,
        backgroundColor: theme.palette.danger.main,
    },
    paper: {
        padding: theme.spacing.unit * 2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    divider: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit,
    },
    emptyList: {
        marginTop: theme.spacing.unit * 3,
    }
});

class Opgaver extends React.Component {
    constructor() {
        super();
        this.state = {
            text: '',
            tab: 0
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.fetchOpgaver();
    }

    handleChange = (event, value) => {
        this.props.dispatch(setOpgaverTab(value));
    };

    fetchOpgaver() {
        this.props.dispatch(fetchOpgaver());
    }

    goToOpgave(id, checksum) {
        this.props.history.push(`/opgaver/${id}/${checksum}`);
    }

    render() {
        const { classes, opgaver, tab } = this.props;
        return (
            <div className={classes.root}>
                <AppBar position="static" color="secondary" style={{ position: 'fixed' }}>
                    <Tabs value={tab} onChange={this.handleChange} fullWidth centered>
                        <Tab label="Åbne" />
                        <Tab label="Besvarede" />
                    </Tabs>
                </AppBar>
                <div style={{ paddingTop: 48 }}>
                    {tab === 0 && (
                        <TabContainer>
                            <div className={classes.tabRoot}>
                                {opgaver && <div>
                                    {opgaver.state === "loading" && 
                                        <Grid container direction="row"
                                        justify="center"
                                        alignItems="center"><CircularProgress className={classes.progress} /></Grid>
                                    } 
                                    {opgaver.state === "loaded" &&
                                    <Grid container justify="center">
                                        <Grid item xs={12} sm={12} md={9} lg={6}>
                                            <Typography variant="title"  className={classes.title}>
                                                <Grid container
                                                    direction="row"
                                                    justify="space-between"
                                                    alignItems="center">
                                                    <span>Åbne opgaver</span>
                                                    <Chip
                                                        label={`${opgaver.opgaver ? opgaver.opgaver.filter(o => !o.is_answered && o.is_open).length : 0} opgaver`}
                                                        className={classes.chipHold}
                                                        />
                                                </Grid>
                                            </Typography>
                                                {opgaver.opgaver ? opgaver.opgaver.filter(o => !o.is_answered && o.is_open).length > 0 ? <Paper>
                                                <List className={classes.list}>
                                                    {opgaver.opgaver
                                                        ? opgaver.opgaver.filter(o => !o.is_answered && o.is_open).map((opgave, index) => (
                                                            <div key={`item-${index}`}>
                                                                {index > 0 && <Divider />}
                                                                <ListItem
                                                                    onClick={() => {
                                                                        this.goToOpgave(opgave.id, opgave.checksum);
                                                                    }}>
                                                                        {opgave.is_answered === "senior" && <ListItemIcon><Avatar className={classes.seniorAvatar}>S</Avatar></ListItemIcon>}
                                                                        <ListItemText
                                                                            primary={`${opgave.headline}`}
                                                                        />
                                                                </ListItem>
                                                            </div>
                                                        ))
                                                        : null}
                                                </List>
                                                </Paper>: <Typography variant="caption" align="center" className={classes.emptyList}>Der er ikke flere åbne opgaver</Typography> : null}
                                        </Grid>
                                    </Grid>}
                                </div> }
                            </div>
                        </TabContainer>
                    )}
                    {tab === 1 && (
                        <TabContainer>
                            <div className={classes.tabRoot}>
                                {opgaver && <div>
                                    {opgaver.state === "loading" && 
                                        <Grid container direction="row"
                                        justify="center"
                                        alignItems="center"><CircularProgress className={classes.progress} /></Grid>
                                    } 
                                    {opgaver.state === "loaded" &&
                                    <Grid container justify="center">
                                        <Grid item xs={12} sm={12} md={9} lg={6}>
                                            <Typography variant="title"  className={classes.title}>
                                                <Grid container
                                                    direction="row"
                                                    justify="space-between"
                                                    alignItems="center">
                                                    <span>Besvarede opgaver</span>
                                                    <Chip
                                                        label={`${opgaver.opgaver ? opgaver.opgaver.filter(o => o.is_answered && o.is_open).length : 0} opgaver`}
                                                        className={classes.chipHold}
                                                        />
                                                </Grid>
                                            </Typography>
                                                {opgaver.opgaver ? opgaver.opgaver.filter(o => o.is_answered && o.is_open).length > 0 ? <Paper>
                                                <List className={classes.list}>
                                                    {opgaver.opgaver
                                                        ? opgaver.opgaver.filter(o => o.is_answered && o.is_open).map((opgave, index) => (
                                                            <div key={`item-${index}`}>
                                                                {index > 0 && <Divider />}
                                                                <ListItem
                                                                    onClick={() => {
                                                                        this.goToOpgave(opgave.id, opgave.checksum);
                                                                    }}>
                                                                        {opgave.is_answered === "senior" && <ListItemIcon><Avatar className={classes.seniorAvatar}>S</Avatar></ListItemIcon>}
                                                                        <ListItemText
                                                                            primary={`${opgave.headline}`}
                                                                        />
                                                                </ListItem>
                                                            </div>
                                                        ))
                                                        : null}
                                                </List>
                                                </Paper>: <Typography variant="caption" align="center" className={classes.emptyList}>I har ikke besvaret nogen opgaver endnu</Typography> : null}
                                        </Grid>
                                    </Grid>}
                                </div> }
                            </div>
                        </TabContainer>
                    )}
                </div>
            </div>
        );
    }
}

Opgaver.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    return {
        opgaver: state.opgaver,
        tab: state.defaultTab.opgaver,
    };
}

export default connect(mapStateToProps)(withStyles(styles)(Opgaver));
