import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import AppBar from 'material-ui/AppBar';
import Tabs, { Tab } from 'material-ui/Tabs';
import Typography from 'material-ui/Typography';
import List, { ListItem, ListItemIcon, ListItemText, ListSubheader, ListItemSecondaryAction } from 'material-ui/List';
import { Divider } from 'material-ui';
import Chip from 'material-ui/Chip';
import Paper from 'material-ui/Paper';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { fetchPosterIsNeeded } from './actions/poster';
import Button from 'material-ui/Button';
import RefreshIcon from '@material-ui/icons/Refresh';
import MyLocationIcon from '@material-ui/icons/MyLocation';
import MapIcon from '@material-ui/icons/Map';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from 'react-google-maps';
import { compose, withProps } from 'recompose';
import { setZoomLevel, setPosition, setCenter } from './actions/position';
import { setDefaultTab } from './actions/defaultTab';
import CheckIcon from '@material-ui/icons/Check';
import { green } from 'material-ui/colors';
import Menu, { MenuItem } from 'material-ui/Menu';

function TabContainer(props) {
    return <Typography component="div">{props.children}</Typography>;
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        height: `calc(100vh - ${56 + 56 + 48}px)`,
    },
    list: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        marginBottom: 56,
        paddingBottom: 82,
    },
    listSubheader: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        backgroundColor: '#f9f9f9',
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    chip: {
        margin: theme.spacing.unit,
    },
    buttonRefresh: {
        margin: theme.spacing.unit,
        position: 'fixed',
        zIndex: 10,
        bottom: 62,
        left: theme.spacing.unit * 5 + 56 + 56,
        backgroundColor: theme.palette.success.main,
        color: 'white',
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    buttonMaps: {
        margin: theme.spacing.unit,
        position: 'fixed',
        zIndex: 10,
        bottom: 62,
        left: theme.spacing.unit * 3 + 56,
        backgroundColor: theme.palette.success.main,
        color: 'white',
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    buttonLocation: {
        margin: theme.spacing.unit,
        position: 'fixed',
        zIndex: 10,
        bottom: 62,
        left: theme.spacing.unit,
        backgroundColor: theme.palette.success.main,
        color: 'white',
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    checkInIcon: {
        color: green[500],
    },
    chipQueue: {
        color: 'white',
        backgroundColor: '#326295',
        marginLeft: theme.spacing.unit,
    },
});

class Poster extends React.Component {
    constructor() {
        super();
        this.state = {
            finalekapitler: null,
            poster: null,
            currentPosition: null,
            mapPosition: null,
            positionAccepted: null,
            bounds: null,
            shouldFitBounds: true,
            firstFitBounds: true,
            anchorEl: null,
            lastPositionFetch: null,
            defaultTab: 1
        };

        this.mapComponent = React.createRef();
        this.fetchPoster = this.fetchPoster.bind(this);
        this.centerOnPosition = this.centerOnPosition.bind(this);
        this.fitToKapitel = this.fitToKapitel.bind(this);
    }

    handleChange = (event, value) => {
        this.setState({ defaultTab: value });
        this.props.dispatch(setDefaultTab(value));
    };

    handleClick = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    componentDidMount() {
        this.fetchPoster();
    }

    componentDidUpdate() {
        if (this.state.firstFitBounds || this.state.shouldFitBounds) {
            // this.fitBounds();
            // this.setState({
            //     firstFitBounds: false,
            //     shouldFitBounds: false,
            // });
        }
    }

    componentWillReceiveProps(nextProps, prevState) {
        if (this.state.shouldFitBounds) {
            // this.fitBounds();
            // this.setState({
            //     shouldFitBounds: false,
            // });
        }
    }

    fetchPoster() {
        console.log('fetchPoster');
        var _this = this;
        const geo_options = {
            enableHighAccuracy: true,
            maximumAge: 100,
            timeout: 60000,
        };
        if (navigator.geolocation) {
            var watchID = navigator.geolocation.watchPosition(
                function (position) {
                    const newPosition = {
                        lat: parseFloat(position.coords.latitude),
                        lng: parseFloat(position.coords.longitude),
                    };
                    _this.props.dispatch(setPosition(newPosition));
                    _this.fetchPosterIfNeeded(position.coords.latitude, position.coords.longitude, position.coords.accuracy);
                    _this.setState({
                        positionAccepted: true,
                        shouldFitBounds: true,
                    });
                    if (_this.mapComponent.current) {
                        if (_this.mapComponent.current.getBounds()) {
                            if (!_this.mapComponent.current.getBounds().contains(newPosition)) {
                                _this.props.dispatch(setCenter(newPosition));
                            }
                        }
                    }
                },
                function (position) {
                    _this.setState({
                        positionAccepted: false,
                    });
                },
                geo_options,
            );
            setTimeout(function () { navigator.geolocation.clearWatch(watchID); }, 5000);
        } else {
            console.error('geolocation is null');
        }
    }



    fetchPosterIfNeeded(lat, lng, accuracy) {
        // Stop fetching for same location in 5 seconds
        const time = parseInt(Date.now() / 1000 / 12, 10);
        const newLocation = { lat, lng, accuracy, time };
        if (JSON.stringify(this.state.lastPositionFetch) !== JSON.stringify(newLocation)) {
            this.setState({ lastPositionFetch: newLocation });

            this.props.dispatch(fetchPosterIsNeeded(lat, lng, accuracy));
        }
    }

    centerOnPosition() {
        this.props.dispatch(setCenter(this.props.position));
    }

    fitToKapitel(id) {
        const bounds = new window.google.maps.LatLngBounds();
        if (this.props.poster) {
            const kapitel_poster = this.props.poster.filter(p => p.finalekapitel_id === id);
            if (kapitel_poster.length > 0) {
                for (var i = 0; i < kapitel_poster.length; i++) {
                    bounds.extend(new window.google.maps.LatLng(kapitel_poster[i].lat, kapitel_poster[i].lng));
                }
            }
        }
        if (this.mapComponent.current) {
            this.mapComponent.current.fitBounds(bounds);
        }
        this.handleClose();
    }

    fitBounds() {
        const bounds = new window.google.maps.LatLngBounds();
        if (this.props.position) {
            bounds.extend(new window.google.maps.LatLng(this.props.position.lat, this.props.position.lng));
        }
        if (this.props.poster) {
            if (this.props.poster.length > 5) {
                for (var i = 0; i < 5; i++) {
                    bounds.extend(new window.google.maps.LatLng(this.props.poster[i].lat, this.props.poster[i].lng));
                }
            }
        }
        if (this.mapComponent.current) {
            this.mapComponent.current.fitBounds(bounds);
        }
    }

    goToPost(id) {
        this.props.history.push(`/poster/${id}`);
    }

    _handleZoomChanged() {
        const zoomLevel = this.mapComponent.current.getZoom();
        if (zoomLevel !== this.props.zoomLevel) {
            this.props.dispatch(setZoomLevel(zoomLevel));
        }
    }

    _handleCenterChanged() {
        const center = this.mapComponent.current.getCenter();
        clearTimeout(this.timeout);

        // Make a new timeout set to go off in 500ms
        var _this = this;
        this.timeout = setTimeout(function () {
            _this.props.dispatch(setCenter(center));
        }, 500);
    }

    render() {
        const { classes, zoomLevel, center, position } = this.props; //defaultTab

        const { anchorEl, defaultTab } = this.state;

        return (
            <div className={classes.root}>
                <AppBar position="static" color="secondary" style={{ position: 'fixed' }}>
                    <Tabs value={defaultTab} onChange={this.handleChange} fullWidth centered>
                        <Tab label="Kort" />
                        <Tab label="Liste" />
                    </Tabs>
                </AppBar>
                <div style={{ paddingTop: 48 }}>
                    {defaultTab === 0 && (
                        <TabContainer>
                            {this.state.positionAccepted === null && (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: 'calc(100vh - 160px)',
                                    }}
                                >
                                    <Typography style={{ textAlign: 'center' }}>
                                        Du skal give VM Live adgang til din <br />position for at kunne bruge kortet
                                    </Typography>
                                </div>
                            )}
                            {this.state.positionAccepted === false && (
                                <div
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: 'calc(100vh - 160px)',
                                    }}
                                >
                                    <Typography style={{ textAlign: 'center' }}>
                                        VM Live har ikke fået adgang til din position,<br />og kan desværre ikke vise
                                        kortet
                                    </Typography>
                                </div>
                            )}
                            {this.state.positionAccepted === true && (
                                <div>
                                    <div>
                                        <Button
                                            aria-owns={anchorEl ? 'simple-menu' : null}
                                            aria-haspopup="true"
                                            onClick={this.handleClick}
                                            variant="fab"
                                            className={classes.buttonLocation}
                                        >
                                            <MapIcon />
                                        </Button>
                                        <Menu
                                            id="simple-menu"
                                            anchorEl={anchorEl}
                                            open={Boolean(anchorEl)}
                                            onClose={this.handleClose}
                                            anchorOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                            }}
                                            transformOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                        >
                                            {this.props.finalekapitler
                                                ? this.props.finalekapitler.length > 0
                                                    ? this.props.finalekapitler.map(kapitel => {
                                                        return (
                                                            <MenuItem
                                                                key={kapitel.id}
                                                                onClick={() => this.fitToKapitel(kapitel.id)}
                                                            >
                                                                Kapitel {kapitel.betegnelse}
                                                            </MenuItem>
                                                        );
                                                    })
                                                    : null
                                                : null}
                                        </Menu>
                                    </div>
                                    <Button
                                        variant="fab"
                                        color="inherit"
                                        aria-label="add"
                                        className={classes.buttonMaps}
                                        onClick={this.centerOnPosition}
                                    >
                                        <MyLocationIcon />
                                    </Button>
                                    <Button
                                        variant="fab"
                                        color="inherit"
                                        aria-label="add"
                                        className={classes.buttonRefresh}
                                        onClick={this.fetchPoster}
                                    >
                                        <RefreshIcon />
                                    </Button>
                                    <GoogleMap
                                        ref={this.mapComponent}
                                        center={
                                            center
                                                ? center
                                                : {
                                                    lat: 55.7176978,
                                                    lng: 12.3797288,
                                                }
                                        }
                                        zoom={zoomLevel ? zoomLevel : 15}
                                        onCenterChanged={this._handleCenterChanged.bind(this)}
                                        onZoomChanged={this._handleZoomChanged.bind(this)}
                                    >
                                        {position && (
                                            <Marker
                                                key={`${position.lat}-${position.lng}`}
                                                position={position}
                                                icon="/cur_pos.png"
                                            />
                                        )}
                                        {this.props.poster
                                            ? this.props.poster.map(marker => (
                                                <Marker
                                                    key={`${marker.id}-${marker.lat}-${marker.lng}`}
                                                    position={{
                                                        lat: parseFloat(marker.lat),
                                                        lng: parseFloat(marker.lng),
                                                    }}
                                                    onClick={() => {
                                                        this.goToPost(marker.id);
                                                    }}
                                                    icon={
                                                        marker.map_marker_image
                                                            ? `/images/icons/${marker.map_marker_image}`
                                                            : null
                                                    }
                                                    label={{
                                                        text: marker.betegnelse_maplabel || marker.betegnelse,
                                                        color: 'white',
                                                        fontSize: '10px',
                                                        fontWeight: 'bold',
                                                        className: 'marker-label-position',
                                                    }}
                                                />
                                            ))
                                            : null}
                                    </GoogleMap>
                                </div>
                            )}
                        </TabContainer>
                    )}
                    {defaultTab === 1 && (
                        <TabContainer>
                            <Button
                                variant="fab"
                                color="inherit"
                                aria-label="add"
                                className={classes.buttonRefresh}
                                onClick={this.fetchPoster}
                            >
                                <RefreshIcon />
                            </Button>
                            <Paper className={classes.paper}>
                                <List className={classes.list} subheader={<li />}>
                                    {this.props.finalekapitler ? (
                                        this.props.finalekapitler.length > 0 ? (
                                            this.props.poster ? (
                                                this.props.poster.length > 0 ? (
                                                    this.props.poster
                                                        .reduce((total, val) => {
                                                            if (!total.includes(val.finalekapitel_id)) {
                                                                total.push(val.finalekapitel_id);
                                                            }
                                                            return total;
                                                        }, [])
                                                        .map(kapitel_id => {
                                                            const kapitel = this.props.finalekapitler.filter(
                                                                p => p.id === kapitel_id,
                                                            )[0];
                                                            if (kapitel) {
                                                                return (
                                                                    <li
                                                                        key={`section-${kapitel.betegnelse}`}
                                                                        className={classes.listSection}
                                                                    >
                                                                        <ul className={classes.ul}>
                                                                            <ListSubheader
                                                                                className={classes.listSubheader}
                                                                            >{`Kapitel ${kapitel.betegnelse
                                                                                }`}</ListSubheader>
                                                                            {this.props.poster
                                                                                .filter(
                                                                                    p =>
                                                                                        p.finalekapitel_id ===
                                                                                        kapitel.id,
                                                                                )
                                                                                .map(post => (
                                                                                    <div key={`item-${post.id}`}>
                                                                                        <ListItem
                                                                                            onClick={() => {
                                                                                                this.goToPost(post.id);
                                                                                            }}
                                                                                        >
                                                                                            <ListItemIcon>
                                                                                                {post.done ? (
                                                                                                    <CheckIcon
                                                                                                        className={
                                                                                                            classes.checkInIcon
                                                                                                        }
                                                                                                    />
                                                                                                ) : (
                                                                                                    <img
                                                                                                        alt={
                                                                                                            post.map_marker_image
                                                                                                        }
                                                                                                        src={`/images/icons/${post.map_marker_image
                                                                                                            }`}
                                                                                                    />
                                                                                                )}
                                                                                            </ListItemIcon>
                                                                                            <ListItemText
                                                                                                primary={<div>
                                                                                                    Post {post.betegnelse}
                                                                                                    {post.patrulje_queue_position &&
                                                                                                        <Chip label={<div><b>{post.patrulje_queue_position}</b> i køen</div>} className={classes.chipQueue} />
                                                                                                    }
                                                                                                </div>}
                                                                                            />
                                                                                            <ListItemSecondaryAction>
                                                                                                <Chip
                                                                                                    label={
                                                                                                        post.distance <=
                                                                                                            1000
                                                                                                            ? `${post.distance
                                                                                                            } m`
                                                                                                            : `${(
                                                                                                                post.distance /
                                                                                                                1000
                                                                                                            )
                                                                                                                .toFixed(
                                                                                                                    1,
                                                                                                                )
                                                                                                                .replace(
                                                                                                                    /\B(?=(\d{3})+(?!\d))/g,
                                                                                                                    ',',
                                                                                                                )} km`
                                                                                                    }
                                                                                                    className={
                                                                                                        classes.chip
                                                                                                    }
                                                                                                />
                                                                                            </ListItemSecondaryAction>
                                                                                        </ListItem>
                                                                                        <Divider />
                                                                                    </div>
                                                                                ))}
                                                                        </ul>
                                                                    </li>
                                                                );
                                                            } else {
                                                                return null;
                                                            }
                                                        })
                                                ) : (
                                                    <div />
                                                )
                                            ) : (
                                                <div />
                                            )
                                        ) : (
                                            <div />
                                        )
                                    ) : (
                                        <div />
                                    )}
                                </List>
                            </Paper>
                        </TabContainer>
                    )}
                </div>
            </div>
        );
    }
}

Poster.propTypes = {
    classes: PropTypes.object.isRequired,
};
function mapStateToProps(state) {
    return {
        poster: state.poster,
        finalekapitler: state.finalekapitler,
        zoomLevel: state.position.zoomLevel,
        center: state.position.center,
        position: state.position.position,
        defaultTab: state.defaultTab.poster,
    };
}

export default compose(
    withProps({
        googleMapURL:
            'https://maps.googleapis.com/maps/api/js?key=AIzaSyCUPVsszca4zGT5Qm_6bNAyjoYOrFBW8Es&v=3.exp&libraries=geometry,drawing,places',
        loadingElement: (
            <div
                style={{
                    height: `100%`,
                }}
            />
        ),
        containerElement: (
            <div
                style={{
                    marginTop: 56,
                    height: `calc(100vh - ${56 + 56}px)`,
                    display: 'flex',
                    flexDirection: 'column-reverse',
                }}
            />
        ),
        mapElement: (
            <div
                style={{
                    height: `100%`,
                    width: '100%',
                }}
            />
        ),
    }),
    withScriptjs,
    withGoogleMap,
    connect(mapStateToProps),
    withRouter,
    withStyles(styles),
)(Poster);
