import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web and AsyncStorage for react-native
import { PersistGate } from 'redux-persist/integration/react';
import rootReducer from './reducers';
import thunkMiddleware from 'redux-thunk';

const persistConfig = {
    key: 'root',
    storage,
};

const logger = store => next => action => {
    // console.log('dispatching', action);
    let result = next(action);
    // console.log('next state', store.getState());
    return result;
};
const crashReporter = store => next => action => {
    try {
        return next(action);
    } catch (err) {
        console.error('Caught an exception!', err);
        throw err;
    }
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const preloadedstate = {
    activated: null,
    badges: null,
    finalekapitler: null,
    poster: null,
    sessionInfo: null,
    token: null,
    defaultTab: 0,
    position: { zoomLevel: 15, center: { lat: 55.7176978, lng: 12.3797288 } },
};

const store = createStore(persistedReducer, preloadedstate, applyMiddleware(thunkMiddleware, logger, crashReporter));
const persistor = persistStore(store);

ReactDOM.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </PersistGate>
    </Provider>,
    document.getElementById('root'),
);
