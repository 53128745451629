import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import Paper from 'material-ui/Paper';
import { CircularProgress } from 'material-ui/Progress';
import GridList, { GridListTile } from 'material-ui/GridList';
import { withRouter } from 'react-router-dom';
import { fetchBadgesIfNeeded } from './actions/badges';
import { Chip, Typography } from 'material-ui';
import { connect } from 'react-redux';
import Grid from 'material-ui/Grid';
import { green, grey } from 'material-ui/colors';

const styles = theme => ({
    root: {
        padding: theme.spacing.unit * 2,
    },
    gridRoot: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        backgroundColor: theme.palette.background.paper,
        paddingTop: 12
    },
    badgeImg: {
        width: '100%',
        maxHeight: '80%',
    },
    chip: {
        margin: theme.spacing.unit,
        padding: theme.spacing.unit,
        backgroundColor: grey[500],
        color: grey[50],
        maxWidth: 100,
        borderRadius: theme.spacing.unit * 4,
        fontSize: 12
    },
    chipHas: {
        margin: theme.spacing.unit,
        padding: theme.spacing.unit,
        backgroundColor: green[700],
        color: green[50],
        maxWidth: 100,
        borderRadius: theme.spacing.unit * 4,
        fontSize: 12
    },
    chipLevel: {
        margin: theme.spacing.unit,
        backgroundColor: green[700],
        color: green[50],
        position: 'absolute',
        fontSize: 15,
        left: 0
    },
    tile: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        maxHeight: '100%',
    },
});

class Badges extends React.Component {
    state = {
        fetching: true,
        badges: null,
        badge: null,
        scrolled: false,
    };

    fetchBadges() {
        this.props.dispatch(fetchBadgesIfNeeded());
    }

    goToBadge(id) {
        this.props.history.push(`/badges/${id}`);
    }

    componentDidMount() {
        this.fetchBadges();
    }

    componentDidUpdate() {
        if (this.state.scrolled === false) {
            window.scrollTo(0, 0);
            this.setState({
                scrolled: true,
            });
        }
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Grid container justify="center">
                    <Grid item xs={12} sm={12} md={9} lg={6}>
                        <Paper className={classes.paper}>
                            {!this.props.badges ? (
                                <CircularProgress className={classes.progress} size={50} />
                            ) : (
                                <div className={classes.gridRoot}>
                                    <GridList className={classes.gridList} cols={3}>
                                        {this.props.badges.map(badge => (
                                            <GridListTile
                                                key={badge.id}
                                                cols={1}
                                                onClick={() => {
                                                    this.goToBadge(badge.id);
                                                }}
                                            >
                                                <div className={classes.tile}>
                                                    <div
                                                        className={classes.badgeImg}
                                                        style={
                                                            !badge.has_badge
                                                                ? {
                                                                      filter: 'grayscale(100%)',
                                                                      opacity: 0.5,
                                                                      height: 100,
                                                                      width: 100,
                                                                      backgroundImage: `url(/images/badges/${badge.short_name}.svg)`,
                                                                      backgroundSize: '100px',
                                                                      backgroundRepeat: 'no-repeat',
                                                                      backgroundPosition: 'center',
                                                                      border: '4px solid black',
                                                                      borderRadius: '100%'
                                                                  }
                                                                : {
                                                                    height: 100,
                                                                    width: 100,
                                                                    backgroundImage: `url(/images/badges/${badge.short_name}.svg)`,
                                                                    backgroundSize: '100px',
                                                                    backgroundRepeat: 'no-repeat',
                                                                    backgroundPosition: 'center',
                                                                    border: '4px solid black',
                                                                    borderRadius: '100%'
                                                                }
                                                        }
                                                        alt={badge.title}
                                                    ></div>
                                                    {badge.has_badge &&
                                                        badge.level && (
                                                            <Chip label={badge.level} className={classes.chipLevel} />
                                                        )}
                                                    <Typography
                                                        className={badge.has_badge ? classes.chipHas : classes.chip}
                                                        align="center"
                                                    >
                                                        {badge.title}
                                                    </Typography>
                                                </div>
                                            </GridListTile>
                                        ))}
                                    </GridList>
                                </div>
                            )}
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

Badges.propTypes = {
    classes: PropTypes.object.isRequired,
};
function mapStateToProps(state) {
    return {
        badges: state.badges,
    };
}

export default connect(mapStateToProps)(withRouter(withStyles(styles)(Badges)));
