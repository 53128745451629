import React, { Component } from 'react';
import './App.css';
import { MuiThemeProvider, createMuiTheme } from 'material-ui/styles';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import AppBar from './AppBar';
import Login from './Login';
import SendSMS from './SendSMS';
import Opgaver from './Opgaver';
import Opgave from './Opgave';
import Poster from './Poster';
import Post from './Post';
import Badges from './Badges';
import Badge from './Badge';
import Information from './Information';
import CustomButtonNavigation from './CustomBottomNavigation';
import { green, red, grey } from 'material-ui/colors';
import { withStyles } from 'material-ui/styles';
import { connect } from 'react-redux';
import Hemmeligt from './Hemmeligt';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: '#273f69',
        },
        secondary: {
            main: '#326295',
        },
        danger: {
            main: red[500],
        },
        success: {
            main: green[500],
        },
    },
    mixins: {
        toolbar: {
            minHeight: 56,
        },
    },
    overrides: {
        MuiBottomNavigationAction: {
            // Name of the styleSheet
            root: {
                color: grey[400],
            },
            selected: {
                color: grey[50],
            },
        },
    },
});

const styles = {
    root: {
        marginTop: theme.mixins.toolbar.minHeight,
        marginBottom: 56,
    },
};

class App extends Component {
    state = {
        sessionInfo: {
            authType: null,
            telefonnummer: null,
            needActivation: true,
            patruljeData: { patruljenavn: null },
        },
        token: localStorage.getItem('token'),
    };

    setAuth = (sessionInfo, token) => {
        localStorage.setItem('token', token);
        this.setState({ sessionInfo: sessionInfo, token: token });
    };

    render() {
        const { classes } = this.props;
        return (
            <Router>
                <MuiThemeProvider theme={theme}>
                    <AppBar />
                    {this.props.activated ? (
                        <div className={classes.root}>
                            <Switch>
                                <Route exact path="/" component={Poster} />
                                <Route exact path="/poster" component={Poster} />
                                <Route exact path="/poster/:id" component={Post} />
                                <Route exact path="/badges" component={Badges} />
                                <Route exact path="/badges/:id" component={Badge} />
                                <Route exact path="/opgaver" component={Opgaver} />
                                <Route exact path="/opgaver/:id/:checksum" component={Opgave} />
                                <Route exact path="/sendsms" component={SendSMS} />
                                <Route exact path="/information" component={Information} />
                                <Route exact path="/information/:tab" component={Information} />
                                <Route exact path="/hemmeligt" component={Hemmeligt} />
                            </Switch>
                            <CustomButtonNavigation />
                        </div>
                    ) : (
                        <div className={classes.root}>
                            <Login loginDone={this.setAuth} />
                        </div>
                    )}
                </MuiThemeProvider>
            </Router>
        );
    }
}
function mapStateToProps(state) {
    return {
        activated: state.activated,
    };
}

export default connect(mapStateToProps)(withStyles(styles)(App));
