import { RECEIVE_SESSIONINFO, INVALIDATE_SESSIONINFO } from '../actions/login';
import { INVALIDATE_ACTIVATION } from '../actions/activated';
function reducer(state = null, action) {
    switch (action.type) {
        case RECEIVE_SESSIONINFO:
            return action.sessionInfo;
        case INVALIDATE_SESSIONINFO:
            return null;
        case INVALIDATE_ACTIVATION:
            return null;
        default:
            return state;
    }
}

export default reducer;
