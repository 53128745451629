import { RECEIVE_ACTIVATION, INVALIDATE_ACTIVATION } from '../actions/activated';
function reducer(state = null, action) {
    switch (action.type) {
        case RECEIVE_ACTIVATION:
            return action.activated;
        case INVALIDATE_ACTIVATION:
            return null;
        default:
            return state;
    }
}

export default reducer;
