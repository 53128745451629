import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import Button from 'material-ui/Button';
import Typography from 'material-ui/Typography';
import Paper from 'material-ui/Paper';
import Grid from 'material-ui/Grid';
import TextField from 'material-ui/TextField';
import { Divider } from 'material-ui';
import { connect } from 'react-redux';
import { fetchTokenIfNeeded, invalidateToken, invalidateSessionInfo, resendActivationcode } from './actions/login';
import { fetchActivationIfNeeded } from './actions/activated';
import { green, red } from 'material-ui/colors';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing.unit * 2,
    },
    videreButton: {
        marginTop: theme.spacing.unit * 2,
    },
    aktiverButton: {
        marginTop: theme.spacing.unit * 2,
        backgroundColor: theme.palette.success.main,
        color: green[50],
        '&:hover': {
            backgroundColor: green[700],
        },
        '&:disabled': {
            backgroundColor: green[200],
        },
    },
    gensendButton: {
        marginTop: theme.spacing.unit * 2,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.getContrastText(theme.palette.primary.main),
        '&:hover': {
            backgroundColor: theme.palette.primary.main,
        },
    },
    annullerButton: {
        marginTop: theme.spacing.unit * 2,
        backgroundColor: theme.palette.danger.main,
        color: red[50],
        '&:hover': {
            backgroundColor: red[700],
        },
    },
    paper: {
        padding: theme.spacing.unit * 2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    divider: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit,
    },
    marginTop: {
        marginTop: theme.spacing.unit * 2,
    },
});

class Login extends React.Component {
    constructor() {
        super();
        this.state = {
            telefonnummer: '',
            startkode: '',
            aktiveringskode: '',
            validate: false,
        };
        this.handleChange = this.handleChange.bind(this);
    }

    sendStepOne = () => {
        this.props.dispatch(fetchTokenIfNeeded(this.state.telefonnummer, this.state.startkode));
    };

    sendStepTwo = () => {
        this.props.dispatch(fetchActivationIfNeeded(this.state.aktiveringskode));
    };

    resendActivationcode = () => {
        this.props.dispatch(resendActivationcode(this.props.token));
    };

    cancelStepTwo = () => {
        this.props.dispatch(invalidateToken());
        this.props.dispatch(invalidateSessionInfo());
        this.setState({
            telefonnummer: '',
            startkode: '',
            aktiveringskode: '',
            validate: false,
        });
    };

    handleChange(evt) {
        this.setState({ [evt.target.name]: evt.target.value });
    }

    render() {
        const { classes, loginError } = this.props;
        return (
            <div className={classes.root}>
                <Grid container justify="center">
                    <Grid item xs={12} sm={12} md={9} lg={6}>
                        <Paper className={classes.paper}>
                            {!this.props.sessionInfo ? (
                                <div>
                                    <Typography variant="headline" align="left">
                                        Log på
                                    </Typography>
                                    {/* <form
                                    className={classes.form}
                                    noValidate
                                    autoComplete="off"
                                    onSubmit={this.sendStepOne}
                                > */}
                                    <TextField
                                        name="telefonnummer"
                                        onChange={this.handleChange}
                                        id="telefonnummer"
                                        error={!this.state.telefonnummer && this.state.validate}
                                        value={this.state.telefonnummer}
                                        inputProps={{ 'inputmode': 'numeric', 'pattern': "\\d{8}", }}
                                        label="Telefonnummer"
                                        fullWidth
                                        helperText="Skal være 8 cifre"
                                    />
                                    <TextField
                                        name="startkode"
                                        onChange={this.handleChange}
                                        value={this.state.startkode}
                                        id="startkode"
                                        label="Startkode"
                                        fullWidth
                                        error={loginError}
                                        helperText={loginError ? loginError : '4 tegn som står på jeres patrulje-kort'}
                                    />
                                    <Button
                                        className={classes.videreButton}
                                        variant="raised"
                                        fullWidth={true}
                                        onClick={this.sendStepOne}
                                        disabled={
                                            !(
                                                this.state.telefonnummer.length === 8 &&
                                                this.state.startkode.length === 4
                                            )
                                        }
                                    >
                                        Videre
                                    </Button>
                                    <Typography align="center" className={classes.marginTop}>
                                        <a
                                            href="https://filer.mesterskabet.dk/website/static/VM-LIVE-Userguide-Rev2021-1.pdf"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Brugervejledning
                                        </a>
                                    </Typography>
                                    {/* </form> */}
                                </div>
                            ) : (
                                <div>
                                    <Typography variant="headline" align="left">
                                        Hej {this.props.sessionInfo.patruljeData.patruljenavn}
                                    </Typography>
                                    <Typography variant="subheading" align="left">
                                        Telefonnummer {this.props.sessionInfo.telefonnummer}
                                    </Typography>
                                    {/* <form
                                    className={classes.form}
                                    noValidate
                                    autoComplete="off"
                                    onSubmit={this.sendStepTwo}
                                > */}
                                    <TextField
                                        name="aktiveringskode"
                                        type="text"
                                        onChange={this.handleChange}
                                        id="aktiveringskode"
                                        label="Aktiveringskode"
                                        value={this.state.aktiveringskode}
                                        autoComplete="one-time-code"
                                        inputProps={{ 'inputmode': 'numeric', 'pattern': "\\d{6}", }}
                                        fullWidth
                                        error={loginError}
                                        helperText={loginError ? loginError : 'Den 6 cifrede kode som i modtog på sms'}
                                    />
                                    <Button
                                        className={classes.aktiverButton}
                                        variant="raised"
                                        fullWidth={true}
                                        onClick={this.sendStepTwo}
                                        disabled={!(this.state.aktiveringskode.length === 6)}
                                    >
                                        Aktivér
                                    </Button>
                                    <Divider className={classes.divider} />
                                    <Button
                                        className={classes.gensendButton}
                                        variant="raised"
                                        color="inherit"
                                        fullWidth={true}
                                        onClick={this.resendActivationcode}
                                    >
                                        Gensend aktivering
                                    </Button>
                                    <Button
                                        className={classes.annullerButton}
                                        variant="raised"
                                        fullWidth={true}
                                        onClick={this.cancelStepTwo}
                                    >
                                        Annullér aktivering
                                    </Button>
                                    {/* </form> */}
                                </div>
                            )}
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

Login.propTypes = {
    classes: PropTypes.object.isRequired,
};
function mapStateToProps(state) {
    return {
        sessionInfo: state.sessionInfo,
        token: state.token,
        loginError: state.loginError,
    };
}

export default connect(mapStateToProps)(withStyles(styles)(Login));
