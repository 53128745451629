import { RECEIVE_DIFF } from '../actions/diff';
function reducer(state = null, action) {
    switch (action.type) {
        case RECEIVE_DIFF:
            return action.diff;
        default:
            return state;
    }
}

export default reducer;
