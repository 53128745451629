import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import Paper from 'material-ui/Paper';
import Grid from 'material-ui/Grid';
import { connect } from 'react-redux';
import { Typography } from 'material-ui';
import Countdown from 'react-countdown-now';
import ReactGoogleSheetConnector from 'react-google-sheet-connector';
import { CircularProgress } from 'material-ui/Progress';
import Sheets from './Sheets';
import { fetchDiffAndBadges } from './actions/diff';
import { GoogleSheet } from 'react-google-sheet-connector';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing.unit * 2,
    },
    videreButton: {
        marginTop: theme.spacing.unit * 2,
    },
    aktiverButton: {
        marginTop: theme.spacing.unit * 2,
        backgroundColor: theme.palette.success.main,
    },
    gensendButton: {
        marginTop: theme.spacing.unit * 2,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.text.primary,
    },
    annullerButton: {
        marginTop: theme.spacing.unit * 2,
        backgroundColor: theme.palette.danger.main,
    },
    paper: {
        // padding: theme.spacing.unit * 2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    divider: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit,
    },
});

class SendSMS extends React.Component {
    constructor() {
        super();
        this.state = {
            text: '',
        };
    }

    fetchBadgesAndDiff() {
        this.props.dispatch(fetchDiffAndBadges(this.props.token));
    }

    componentDidMount() {
        this.fetchBadgesAndDiff();
    }

    render() {
        const { classes, diff, badges } = this.props;
        const time = (1526162400 + diff) * 1000; // Den rigtige (2018-05-13 00:00:00)
        return (
            <div className={classes.root}>
                <Grid container justify="center">
                    <Grid item xs={12} sm={12} md={9} lg={6}>
                        {badges ? (
                            badges.filter(b => b.id === 46).length === 0 ? (
                                <Paper className={classes.paper}>
                                    <Typography variant="title" style={{ padding: 16 }}>
                                        Det ser ikke ud til at du har noget at gøre her
                                    </Typography>
                                </Paper>
                            ) : (
                                <div>
                                    {diff ? (
                                        <div>
                                            {new Date() <= time && (
                                                <Paper className={classes.paper}>
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <Typography variant="display3">
                                                        <Countdown
                                                            date={time}
                                                            renderer={props => (
                                                                <div>
                                                                    {props.hours}:{props.minutes}:{props.seconds}
                                                                </div>
                                                            )}
                                                        />
                                                    </Typography>
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <Typography variant="title">55°43'00.1"N 12°24'18.4"E</Typography>
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                </Paper>
                                            )}
                                            {new Date() > time && (
                                                <Paper className={classes.paper}>
                                                    <ReactGoogleSheetConnector
                                                        apiKey={'AIzaSyC_Bi6LfzAkkI3UuaipRFL1b70QwwT4Ats'}
                                                        spreadsheetId={'1dM-MH7yb4WEXSiO2AJiiyGRwh46u_iIiE-u0izyx8JM'}
                                                        spinner={
                                                            <div className="loading-spinner">
                                                                <br />
                                                                <br />
                                                                <br />
                                                                <br />
                                                                <CircularProgress
                                                                    className={classes.progress}
                                                                    size={50}
                                                                />
                                                                <br />
                                                                <br />
                                                                <br />
                                                                <br />
                                                            </div>
                                                        }
                                                    >
                                                        <GoogleSheet child={Sheets} sheetName="Oversigt" />
                                                    </ReactGoogleSheetConnector>
                                                </Paper>
                                            )}
                                        </div>
                                    ) : (
                                        <Paper className={classes.paper}>
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <CircularProgress className={classes.progress} size={50} />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                            <br />
                                        </Paper>
                                    )}
                                </div>
                            )
                        ) : null}
                    </Grid>
                </Grid>
            </div>
        );
    }
}

SendSMS.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    return {
        token: state.token,
        diff: state.diff,
        badges: state.badges,
    };
}

export default connect(mapStateToProps)(withStyles(styles)(SendSMS));
