import { combineReducers } from 'redux';
import token from './token';
import sessionInfo from './sessionInfo';
import defaultTab from './defaultTab';
import poster from './poster';
import badges from './badges';
import diff from './diff';
import teltpladser from './teltpladser';
import position from './position';
import finalekapitler from './finalekapitler';
import activated from './activated';
import loginError from './loginError';
import opgaver from './opgaver';
import opgave from './opgave';

export default combineReducers({
    token,
    sessionInfo,
    defaultTab,
    poster,
    badges,
    diff,
    teltpladser,
    position,
    finalekapitler,
    activated,
    loginError,
    opgaver,
    opgave
});
