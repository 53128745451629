import { RECEIVE_OPGAVER, REQUEST_OPGAVER } from '../actions/opgaver';
import { INVALIDATE_ACTIVATION } from '../actions/activated';
import { CLEAR_CACHE } from '../actions/cache';

function reducer(state = null, action) {
    switch (action.type) {
        case REQUEST_OPGAVER:
            return {state: "loading", error: null, opgaver: null};
        case RECEIVE_OPGAVER:
            return {state: "loaded", error: null, opgaver: action.opgaver};
        case INVALIDATE_ACTIVATION:
            return null;
        case CLEAR_CACHE:
            return null;
        default:
            return state;
    }
}

export default reducer;
