import { SET_LOGINERROR, RECEIVE_SESSIONINFO, REQUEST_TOKEN } from '../actions/login';
import { REQUEST_ACTIVATION } from '../actions/activated';
function reducer(state = null, action) {
    switch (action.type) {
        case REQUEST_TOKEN:
            return null;
        case REQUEST_ACTIVATION:
            return null;
        case RECEIVE_SESSIONINFO:
            return null;
        case SET_LOGINERROR:
            return action.loginError;
        default:
            return state;
    }
}

export default reducer;
