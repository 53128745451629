import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import AppBar from 'material-ui/AppBar';
import Tabs, { Tab } from 'material-ui/Tabs';
import Typography from 'material-ui/Typography';
import Paper from 'material-ui/Paper';
import Grid from 'material-ui/Grid';
import Teltpladser from './Teltpladser';
import List, { ListItem, ListItemText, ListSubheader } from 'material-ui/List';
import { Divider } from 'material-ui';
import ExpansionPanel, { ExpansionPanelSummary, ExpansionPanelDetails } from 'material-ui/ExpansionPanel';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { connect } from 'react-redux';

function TabContainer(props) {
    return (
        <Typography component="div" style={{ margin: 8 * 2 }}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

const styles = theme => ({
    root: {
        flexGrow: 1,
        marginTop: theme.spacing.unit * 3,
    },
    list: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    listSubheader: {
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        backgroundColor: '#f9f9f9',
    },
    listSection: {
        backgroundColor: 'inherit',
    },
    ul: {
        backgroundColor: 'inherit',
        padding: 0,
    },
    heading: {
        fontWeight: 700,
    },
});

class SimpleTabs extends React.Component {
    state = {
        value:
            this.props.match.params.tab === 'info'
                ? 0
                : this.props.match.params.tab === 'teltpladser'
                ? 1
                : this.props.match.params.tab === 'program'
                ? 2
                : 0,
        program: [
            {
                navn: 'Onsdag',
                programpunkter: [
                    { tidspunkt: '20:00', tekst: 'Ankomst til VM Pladsen' },
                    { tidspunkt: '22:30', tekst: 'Velkomst ved Scenen' },
                ],
            },
            {
                navn: 'Torsdag',
                programpunkter: [
                    { tidspunkt: '06:00', tekst: 'Receptionen åbner' },
                    { tidspunkt: '06:00-8:00', tekst: 'Laboratoriet er åbent' },
                    { tidspunkt: '08:00', tekst: 'Posterne åbner' },
                    { tidspunkt: '17:00', tekst: 'Posterne lukker' },
                    { 
                        tidspunkt: '17:00-18:45', 
                        tekst: 'Løbende ankomst og aftensmad til fællesløb ved Nytorv' 
                    },
                    { tidspunkt: '18:50-19:00', tekst: 'Introduktion til fællesløbet' },
                    { tidspunkt: '19:00-20:45', tekst: 'Fællesløb i indre København' },
                    { tidspunkt: '21:08', tekst: 'S-tog, Linje C afgår fra København H' },
                    { tidspunkt: '21:28', tekst: 'S-tog, Linje C afgår fra København H' },
                ],
            },
            {
                navn: 'Fredag',
                programpunkter: [
                    { tidspunkt: '06:00', tekst: 'Receptionen åbner' },
                    { tidspunkt: '06:00-8:00', tekst: 'Laboratoriet er åbent' },
                    { tidspunkt: '08:00', tekst: 'Posterne åbner' },
                    { tidspunkt: '17:00', tekst: 'Posterne lukker' },
                    {
                        tidspunkt: '17:30 - 19:30',
                        tekst: 'Friaften – mulighed for at få taget patruljefotos ved receptionen. ',
                    },
                ],
            },
            {
                navn: 'Lørdag',
                programpunkter: [
                    { tidspunkt: '06:00', tekst: 'Receptionen åbner' },
                    { tidspunkt: '06:00-8:00', tekst: 'Laboratoriet er åbent' },
                    { tidspunkt: '08:00', tekst: 'Posterne åbner' },
                    { tidspunkt: '17:00', tekst: 'Posterne lukker' },
                    { tidspunkt: '17:00-19:00', tekst: 'Laboratoriet er åbent' },
                    { tidspunkt: '19:30', tekst: 'Intro til aftenprogram v. scenen' },
                ],
            },
            {
                navn: 'Søndag',
                programpunkter: [
                    { tidspunkt: '10:00', tekst: 'Gudstjeneste og præmieoverrækkelse' },
                    { tidspunkt: '12:00', tekst: 'Tak for denne gang' },
                ],
            },
        ],
    };

    handleChange = (event, value) => {
        switch (value) {
            case 0:
                this.props.history.push('/information');
                break;
            case 1:
                this.props.history.push('/information/teltpladser');
                break;
            case 2:
                this.props.history.push('/information/program');
                break;
            default:
                this.props.history.push('/information');
                break;
        }
        this.setState({ value });
    };

    render() {
        const { classes, aar } = this.props;
        const { value } = this.state;

        return (
            <div className={classes.root}>
                <AppBar position="static" color="secondary" style={{ position: 'fixed' }}>
                    <Tabs value={value} onChange={this.handleChange} fullWidth centered>
                        <Tab label="Info og hjælp" />
                        <Tab label="Teltpladser" />
                        <Tab label="Program" />
                    </Tabs>
                </AppBar>
                <Grid container justify="center" style={{ paddingTop: 48 }}>
                    <Grid item xs={12} sm={12} md={9} lg={6}>
                        {value === 0 && (
                            <TabContainer>
                                <Paper>
                                    <div style={{ padding: 16 }}>
                                        <Typography variant="headline">Info til Patruljelederen</Typography>
                                        <div>
                                            Velkommen til Patruljeleder Informationen. Her har vi samlet en masse
                                            information til dig som patruljeleder. Klik på de forskellige overskrifter
                                            for at få mere information om emnet.
                                        </div>
                                        <b>
                                            Har I problemer eller spørgsmål så ring til VM Receptionen på{' '}
                                            <a href="tel:70605208">70 60 52 08</a> 
                                        </b>
                                    </div>
                                </Paper>
                                <ExpansionPanel className="informationExpansion">
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography className={classes.heading}>Beredskab 🚨</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <div>
                                            <p>
                                                Skulle uheldet være ude er det vigtigt at du har gjort dig selv
                                                bekendt med vores Beredskabs action cards.
                                            </p>
                                            <br />
                                            <p>
                                                <a
                                                    href="http://filer.mesterskabet.dk/website/static/actioncards.pdf"
                                                    target="new"
                                                >
                                                    Tryk her for at åbne dem.
                                                </a>
                                            </p>
                                        </div>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel className="informationExpansion">
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography className={classes.heading}>Dagsopgaver</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <div>
                                            <p>
                                                Hver dag ved tjek-ud vil I få udleveret et ark med små opgaver, som vi
                                                kalder dagsopgaver. Dem kan I løse, når der er tid til det, fx når I
                                                sidder i toget, står i kø til poster mm. <br />
                                                Opgaverne besvares i VMlive-APP, i menupunktet{' '}
                                                <a href="https://live.mesterskabet.dk/opgaver">DAGSOPGAVER</a>
                                            </p>
                                        </div>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel className="informationExpansion">
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography className={classes.heading}>Huskelisten</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <div>
                                            <p>
                                                Vi har samlet en liste med ting, som det kan være godt at have med rundt på posterne når I er på VM{' '}
                                                {aar}.
                                                <br />
                                                Vand
                                                <br />
                                                Madpakke
                                                <br />
                                                Solcreme
                                                <br />
                                                Førstehjælpsgrej til eget behov
                                                <br />
                                                <br />
                                                På kapitelkortene som I får udleveret dagen før, kan I se hvilke
                                                specifikke materialer I skal bruge på de enkelte poster, så I kun tager
                                                det med I konkret skal bruge den dag. Det kunne fx være:
                                                <br />
                                                Trangia med brændsel
                                                <br />
                                                Kompas
                                                <br />
                                                En salmebog 
                                                <br />
                                                Badetøj
                                                <br />
                                                Håndklæde
                                                <br />
                                                Evt. materiale til forberedelsesposterne
                                                <br />
                                            </p>
                                            <br />
                                            <br />
                                            <p>
                                                ... og man er selvfølgelig velkommen til at medbringe hvad man ellers
                                                tænker man kan få brug for.
                                            </p>
                                        </div>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel className="informationExpansion">
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography className={classes.heading}>Informationer fra Professor Cymborski</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <div>
                                            <a href='https://www.youtube.com/watch?v=Q6Gxe0YXeqo' target='_blank'>Se eksperimentet her</a>
                                            <br/>
                                            <a href='https://www.youtube.com/watch?v=0xIlgCT2CgY' target='_blank'>Se en introduktion til temaet her</a>
                                            <br/>
                                            <br/>
                                            <b>Historien</b>
                                            <br />
                                            <p>
                                                Professor Cymborski og assisterende professor Lønholt leder en forskningsgruppe, der kombinerer forhistorisk arkæologi og kvantemekanik ved Institut for Fortidsforskning. De har formået at skabe ormehuller, der fungerer som portaler mellem vores egen og ukendte verdener. Da de forsøger at etablere menneskelig kontakt gennem portalen, går det dog helt galt, og gode venner og kollegaer er nu forsvundet. Ny forskning tyder heldigvis på, at portalerne kan stabiliseres ved hjælp af højteknologiske komponenter. Der er bare ét problem: Det kræver en stor mængde forhistorisk materiale - i forsøget omtalt som <i>grundstoffer</i> -  at konstruere disse. VMU har tilbudt FDFs hjælp til at lokalisere de sjældne grundstoffer og hjælpe Professor Cymborski og Lønholt med at konstruere de forskellige nødvendige komponenter. Som tak for hjælpen kvitterer vi med point afhængigt af jeres indsats.
                                            </p>
                                            <b>Grundstoffer</b>
                                            <br />
                                            <p>
                                                Patruljen kan finde nogle af grundstofferne ved at tjekke ind på poster. Der findes ikke nogen oversigt over hvilke poster, der indeholder hvilke grundstoffer, og det er formentlig heller ikke alle poster, der gemmer på grundstoffer. Derudover lyder det på Professor Cymborski, at det kræver en vis mængde snilde at lokalisere de lidt sjældnere grundstoffer.
                                                <br/>
                                                <u><b>NB:</b></u> Pas godt på jeres grundstoffer. Hvis de bliver væk, er det ikke muligt at skaffe nye.
                                            </p>
                                            <b>Opskriftshæfte og Komponenter</b>
                                            <br />
                                            <p>
                                                Torsdag morgen mellem kl. 6:00-8:00 kan man i laboratoriet, ved siden af receptionen, hente et opskriftshæfte. Hæftet indeholder en oversigt over hvilke komponenter, der kan bygges, og hvilke grundstoffer, dette kræver. For at kunne bygge en komponent kræver det, udover det rigtige antal grundstoffer, at man har klippet det rigtige serienummer. Serienumrene for de første fem komponenter findes i Laboratoriet, men de resterende bliver I selv nødt til at lokalisere!
                                                <br/>
                                                <br/>
                                                I kan bygge komponenter I Laboratoriet fredag og lørdag morgen fra 6:00-8:00 og lørdag eftermiddag fra 17:00-19:00.
                                                <br/>
                                                <br/>
                                                <u><b>NB:</b></u> Den samme komponent kan godt bygges flere gange, og vi forventer ikke, at man som patrulje alene kan nå at bygge alle komponenter.
                                            </p>
                                            <b>Point</b>
                                            <br />
                                            <p>
                                                I opskriftshæftet kan I finde en indikator for, hvor mange point det giver at bygge de forskellige komponenter. Derudover kvitterer vi med flere point efter jo før på ugen, de bliver bygget. Det vil sige, at komponenter bygget torsdag giver flere point end tilsvarende bygget lørdag.
                                            </p>
                                        </div>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel className="informationExpansion">
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography className={classes.heading}>Konkurrencen</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <div>
                                            <p>
                                                På Væbnermesterskabet dyster vi i en Væbner-Seniorvæbnerrække og en
                                                Seniorrække. Der er flere kategorier man kan vinde.
                                                <br />
                                            </p>
                                            <ul>
                                                <li>Vindere af Netdelen inden for de to aldersrækker.</li>
                                                <li>Vindere af specialistkategorier, inden for begge aldersrækker.</li>
                                                <li>Vindere af Finalen inden for de to aldersrækker</li>
                                                <li>
                                                    Vindere af den samlede konkurrence (Netdelen og Finalen) inde for de
                                                    to aldersrækker.
                                                </li>
                                            </ul>
                                            <br />
                                            <b>Præmier</b>
                                            <br />
                                            Alle vindende patruljer får et diplom med hjem, som de kan hænge op i
                                            kredshuset. Derudover får de samlede vindere af VM {aar} i de to
                                            aldersrækker en vandrepokal med hjem, som de kan beholde til næste gang der
                                            er VM. I væbnerrækken er det en VM-fane og i seniorrækken er det en VM-økse.
                                            Sidst men ikke mindst får alle patruljemedlemmer i de to patruljer, som har
                                            vundet VM {aar}, en håndlavet VM-dolk, som de må beholde til evigt eje.
                                        </div>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel className="informationExpansion">
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography className={classes.heading}>Lejrpladsen</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <div>
                                            <p>
                                                Finalen finder sted på FDF VM Pladsen, et parkområde tæt på Malmparken
                                                st. Den mest præcise adresse er
                                                <br />
                                                <a
                                                    href="https://goo.gl/maps/MBePNUBVcZe7zziE6"
                                                    target="_blank"
                                                    rel="no-follow noopener noreferrer"
                                                >
                                                    FDF VM Pladsen
                                                </a>{' '}
                                                <br />
                                                Kræmmerstien 1 <br />
                                                2750 Ballerup <br />
                                            </p>
                                            <p>
                                                Hvis I har medbragt et samlingstelt der er større end 36m2, er det vigtigt at teltet sættes fast med pløkke i
                                                alle stænger, samt med minimum 4 barduner. Vi har tidligere år oplevet
                                                telte der ikke var sat ordenligt fast og derfor er fløjet rundt på
                                                pladsen. Der er normalt ikke voldsomt meget læ på pladsen.
                                            </p>
                                            <p>
                                                Hver patrulje får et ca 7,6x7,6 meter område stillet til rådighed til
                                                overnatning og anden lejrpladsaktiviter. Vi har fordelt alle patruljer
                                                på pladsen. Alle patruljer fra samme kredse ligger samlet og tæt på
                                                patruljer fra samme netværk. I kan se jeres teltplads under fanen {' '}
                                                <a href="https://live.mesterskabet.dk/information/teltpladser">Teltpladser</a>. 
                                                Lejrpladsen er et offentligt sted, og patruljen har selv ansvaret for
                                                sine ting. Det kan være en god ide at medtage jeres værdigenstande, når
                                                I tjekker ud af lejren.
                                            </p>
                                            <b>Opsætning af samlingstelte</b>
                                            <p>
                                                Hvis I vil opsætte et festtelt, eller lignende må det samlede areal
                                                ikke overstige 50m2. Teltet skal have en afstand på min 5 meter til
                                                andre telte, herunder overnatningstelte. Teltet SKAL sikres med barduner
                                                og kraftige pløkke. <br />
                                                Samlingstelte skal tages ned hvis vindhastigheden overstiger det
                                                tilladte for producenten.
                                            </p>
                                            <br />
                                            <img
                                                src="https://filer.mesterskabet.dk/website/static/plads_app_info_pages.png"
                                                style={{ width: '100%' }}
                                                alt="ankomst oversigtskort"
                                            />
                                            <br />
                                            <br />
                                            På lejrpladsen findes der: <br />
                                            Reception og mobil opladning
                                            <br />
                                            Vandposter (husk at medbringe vanddunke) <br />
                                            Toilet og badefaciliteter. <br />
                                            Parkering. <br />
                                            <br />
                                        </div>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel className="informationExpansion">
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography className={classes.heading}>Madlavning</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <div>
                                            <p>
                                                Det er <b>ikke tilladt </b>at lave mad over bål eller på grill. Vi
                                                foreslår derfor at I laver mad på trangia, som I må benytte på jeres
                                                lejrplads.
                                                <br />
                                                <br />
                                                Hvis I synes I har for lidt plads til at lave mad og spise i lejren,
                                                skal I være velkomne til, at sætte jer uden for jeres område. Det kunne
                                                også være en mulighed for at hilse på andre patruljer, og få lidt større
                                                sammenhold på tværs af kredsene.
                                                <br />
                                                <br />
                                                <b>Brug af gas</b>
                                                <br />
                                                Anvendelse af gas i lejrområder må ske under forudsætning af at:
                                                <br />
                                                Gasflasker ikke har en tilladelig fyldning på mere end 11 kg.
                                                <br />
                                                Der højst anvendes 1 gasflaske pr. kogeapparat/grill.
                                                <br />
                                                Der højst er en reserveflaske pr. enhed.
                                                <br />
                                                Gasflasker sikres mod påkørsel og placeres oprejst. Gas i engangsdåser
                                                til fx trangia er ikke omfattet af ovenstående.
                                                <br />
                                                Gas i engangsdåser til fx trangia er ikke omfattet af ovenstående.
                                                <br />
                                                Til de af jer der måtte få forældre til at komme med mad, er det en god
                                                ide at møde dem på Korttidsparkering så de ved hvor de skal finde jer.
                                            </p>
                                        </div>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel className="informationExpansion">
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography className={classes.heading}>Mobilopladning</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <div>
                                            <p>
                                                I har mulighed for at oplade jeres telefon på lejrpladsen. Det sker oppe ved receptionen, hvor patruljen kan få
                                                udleveret en plastikkasse med fem stikkontakter nede i. Patruljen står
                                                så selv for, at sætte telefonerne til, nede i kassen. HUSK at sætte
                                                telefonerne på lydløs og slå alarmer fra.
                                                <br />
                                                Det er ikke tilladt selv at medbringe en forlængerledning, og derved
                                                sætte mere end fem ting til i kassen. Det er desuden et krav at alt hvad
                                                der sættes til, kan være nede i kassen. Der er altså ikke plads til
                                                eller mulighed for at lade en computer. <br />
                                                <br />
                                                Kassen sættes til strøm i en stor container. Der er kun udvalgte folk
                                                der vil få adgang til denne container, og den vil være forsvarligt
                                                aflåst, når den ikke er åben.
                                            </p>

                                            <b>Åbningstider</b>

                                            <ul> Onsdag: 22:30 - 24:00 </ul>
                                            <ul> Torsdag: 06:00-10:00 og 22:00-23:00</ul>
                                            <ul> Fredag: 06:00-10:00, 16:00-20:00 og 22:00-23:00</ul>
                                            <ul> Lørdag: 06:00-10:00, 16:00-20:00 og 22:00-23:00</ul>
                                            <ul> Søndag: 08:00-10:00</ul>

                                            <p>
                                                For ikke at skabe for meget kø, er det en fordel hvis man sætter alle
                                                telefoner til, eller henter alle telefoner på samme tid. Det er altså
                                                ikke meningen at hvert patruljemedlem kommer op med ti minutters
                                                mellemrum.
                                            </p>
                                        </div>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel className="informationExpansion">
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography className={classes.heading}>Poster og Logistik</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <div>
                                            <p>
                                                <b>Poster</b>
                                                <br />
                                                Alle posterne har åbent i tidsrummet 8.00-17.00. Patruljen bestemmer
                                                selv, hvor mange poster de vil løse i dette tidsrum. I skal have
                                                gennemført sidste post inden kl. 17:00. Postmandskabet vurderer derfor
                                                om det er muligt for jer som patrulje at nå at gennemføre posten inden
                                                kl. 17:00 Posterne er delt i tre strenge, så patruljen kan besøge
                                                posterne på én streng hver dag. Vi fordeler patruljerne på strengene.
                                                Det fremgår af dit patruljekort hvilken streng du skal være på de
                                                enkelte dage. Hver streng er delt i tre kapitler, under hvert kapitel er
                                                der fem poster. Når dagen er slut, kan posterne i dagens streng ikke
                                                besøges mere.
                                            </p>
                                            <p>
                                                Alle poster ligger tæt ved en S-togsstation eller metrostation i
                                                Storkøbenhavn. Det er ikke tilladt at anvende andre transportformer end
                                                offentlig transport (metro, tog og bus) – og patruljens ben
                                                selvfølgelig.
                                            </p>
                                            <p>Eksempel på, hvordan posterne er fordelt</p>
                                            <img
                                                src="https://filer.mesterskabet.dk/website/VMlogistik.png"
                                                style={{ width: '100%' }}
                                                alt="VMlogistik"
                                            />
                                            <br />
                                            <b>Startpost</b>
                                            <br />
                                            På jeres patrulje-kort, vil I kunne se, hvilket kapitel, der er jeres
                                            startkapitel for hver af dagene. I skal vælge en post i dette kapitel, som
                                            dagens første post. I vil ikke kunne løse poster i andre kapitler førend I
                                            har løst en post i startkapitlet.
                                            <br />
                                            <br />
                                            <b>Kort</b>
                                            <br />
                                            I vil få udleveret tre kort hver aften – ét til hvert kapitel i den streng,
                                            I må besøge den næste dag. Kortene bruges kun denne ene dag. Dagen efter får
                                            i tre nye kort til den nye streng.
                                            <br />
                                            <br />
                                            <b>Specialistposter</b>
                                            <br />
                                            Der kåres hvert år vindere inden for en række specialistkategorier. I år er
                                            specialistkategorierne tro og tanker, tons og tempo, underholdning, væbnerfærdigheder og
                                            logik. På jeres kort vil I kunne se hvilke poster, der er specialistposter.
                                            <br />
                                            <br />
                                            <b>Ventetid </b>
                                            <br />
                                            Vi bestræber os på at lave kapaciteten på posterne stor nok, men vær
                                            forberedt på ventetid på enkelte poster. I må gerne skrive jer op i kø på en
                                            post og i mellemtiden tage ud og se om I kan nå at løse nogle andre poster,
                                            men er I ikke tilbage på første post når jeres navn råbes op, må I bag i
                                            køen igen.
                                        </div>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel className="informationExpansion">
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography className={classes.heading}>Regler</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <div>
                                            <p>
                                                Væbnermesterskabet er en stor konkurrence, som mange går op i.
                                                <br />
                                                Det er rigtig dejligt, men derfor er der også nogle regler, der skal
                                                overholdes for at sikre en fair og sjov konkurrence for alle. Overordnet
                                                set kan man stille reglerne op i tre hovedpunkter, som gælder på hele
                                                Finalen:
                                                <br />
                                                <br />
                                                1) Det er ikke tilladt at dele patruljen op. Alle poster, hemmelige
                                                poster, ekstra poster og andre opgaver på Finalen skal løses af en
                                                samlet patrulje. Det er altså ikke tilladt at dele patruljen op, for at
                                                nå mere. Heller ikke selvom det bare er for at hente en information et
                                                andet sted, eller for at stille sig i kø til den næste post. Alt skal
                                                altså løses af en samlet patrulje, med mindre andet er tydeligt angivet
                                                i en postbeskrivelse
                                                <br />
                                                <br />
                                                2) Der må kun benyttes offentlig transport og jeres medbragt ben. For
                                                ikke at give fordele til nogle, er det ikke tilladt at bruge andet end
                                                offentlige transportmidler (bus, tog og metro) til at komme rundt på
                                                Finalen. Herudover må I selvfølgelig, løbe, hoppe og gå rundt til det
                                                hele, lige så tosset I vil. Der er særlige tilfælde, hvor deltagere
                                                grundet handicaps af forskellig art får dispensation for dette efter
                                                aftale med udvalget.
                                                <br />
                                                <br />
                                                3) Postbeskrivelser beskriver hvordan I skal løse en post. I skal følge
                                                reglerne og tankerne i postbeskrivelsen, for at kunne få point for en
                                                post. Det er til enhver tid postmandskabet og kapitellederen der afgør
                                                om reglerne er overholdt og om der kan gives point. I behøver derfor
                                                ikke spekulere i, hvordan I kan finde huller i postbeskrivelserne, da
                                                det kan resultere i, at I ikke kan få point for Jeres indsats. Herudover
                                                henstiller vi selvfølgelig til, at man overholder generel god fairness.
                                                <br />
                                                <br />
                                                Det er til enhver tid Væbnermesterskabsudvalget, der afgør, om en
                                                patrulje har overholdt reglerne eller ej. Det betyder også, at det er
                                                udvalget, der beslutter en eventuel sanktion for en given overtrædelse
                                                af vores regler. Det kan i yderste tilfælde betyde en diskvalifikation.
                                            </p>
                                        </div>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel className="informationExpansion">
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography className={classes.heading}>Sikkerhed og dit ansvar</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <div>
                                            <p>
                                                <b> Patruljens sikkerhed og dit ansvar</b>
                                                <br />
                                                Du er tilmeldt som patruljens ansvarlige leder. Det betyder, at du er
                                                den leder, som kender væbnerne/seniorerne bedst - og som sørger for
                                                patruljen i alle henseender. Det gælder selvfølgelig alle de praktiske
                                                forhold i patruljen, men også i høj grad hvor meget I vil “gå til den”.
                                                Hvis patruljens deltagere skal “presses” på en bestemt post, er det dig
                                                som den ansvarlige leder, der vurderer, hvor grænsen bør gå. Læs mere om
                                                patruljelederens opgave og find gode fifs på hjemmesiden.
                                                <br />
                                                <br />
                                                <b>Alkohol mv.</b>
                                                <br />
                                                Væbnermesterskabet er et landsforbundsarrangement, hvilket betyder at
                                                landsforbundets regler gælder på VM, som de gør det på kurser,
                                                landslejre mv. Alkohol og euforiserende stoffer er strengt forbudt på
                                                Væbnermesterskabet fra start til slut – dvs. også på friaftnen fredag.
                                                <br />
                                                <br />
                                                <b>Ulykker</b>
                                                <br />
                                                Står du i en situation, du ikke selv kan håndtere, kan du altid kontakte
                                                receptionen for hjælp. Nummeret står på patruljekortet. Sker der en
                                                alvorlig ulykke på en post eller undervejs, skal du altid kontakte
                                                receptionen, når ulykken er standset og nødvendig hjælp er tilkaldt.
                                                Ring altid 1-1-2 ved en alvorlig ulykke.
                                                <br />
                                                <br />
                                                <b>Sikkerhed på pladsen</b>
                                                <br />
                                                Vi er mange mennesker samlet – derfor er det vigtigt vi passer på
                                                hinanden.
                                                <br />
                                                <br />
                                                <b>Brandsikkerhed</b>
                                                <br />
                                                Skulle der opstå en brand samles alle ved scenen, samlingspunkt A.
                                                Opstår der brand i forbindelse med fællessamling ved scenen samles alle
                                                ved samlingspunkt B, som er øst for teltpladserne i modsat retning end
                                                scenen. Skulle der opstå en anden situation, hvor det vil være
                                                nødvendigt, at vi samles, vil I få besked herom.
                                                <br />
                                                <br />
                                                <b>Skader</b>
                                                <br />
                                                Hvis du som patruljeleder har brug for hjælp med at vurdere en skade, et
                                                sår eller andet på en deltager, så kig forbi i receptionen. Her kan du
                                                få et par andre øjne på situationen, og sammen kan I vurdere om du og
                                                deltageren skal forbi lægevagten. Medbring dog selv førstehjælpsgrej,
                                                vabelplaster og så videre. Du kan også selv kontakte lægevagten i
                                                København ved at ringe 1813 - de kan også vejlede dig ift hvordan du bør
                                                håndtere situationen.
                                                <br />
                                                <br />
                                                <b>Sikkerhed på posterne</b>
                                                <br />
                                                Når I er på en post eller på en aktivitet, som er en del af programmet,
                                                er det vigtigt at I følger de retningslinjer, der bliver givet af
                                                postmandskabet. Når I går fra post til post, er det patruljelederen, der
                                                har ansvaret og skal sikre patruljens sikkerhed.
                                            </p>
                                        </div>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel className="informationExpansion">
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography className={classes.heading}>Teknik og Telefoner</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <div>
                                            <b>SMS og smartphones</b>
                                            <br />
                                            <p>
                                                Alle patruljer skal have en smartphone (iPhone eller Android) med. Der
                                                vil undervejs på Finalen være brug for, at I kan gå online og finde
                                                informationer. Samtidig skal I bruge vores system VM LIVE (se nedenfor).
                                                Vi bruger også SMS på Finalen til direkte kommunikation. Det vil være en
                                                god idé at have mere end én telefon med, hvis I har mulighed for det, og
                                                også gerne powerbanks så I kan holde strøm på mobiltelefonerne.
                                            </p>
                                            <br />
                                            <b>VM LIVE - overblik på din telefon</b>
                                            <br />
                                            <p>
                                                VM LIVE er vores "web-app" til jeres telefon, når I løser poster på
                                                Finalen. Webadressen til systemet er: <a href='http://vmlive.dk'>vmlive.dk</a>. I bruger APP´en
                                                til at se, hvor posterne ligger og til at ’starte’ og ´slutte´ posterne.
                                                I vil også kunne se den forventede varighed på posten. VM LIVE er lavet
                                                til Android og iPhone, så det er vigtigt at I har sådan en telefon med.
                                            </p>
                                            <br />
                                            <br />
                                            <b>Sådan løses en post – lidt om teknikken </b>
                                            <p>Posterne afvikles i et bestemt mønster, som er skitseret nedenfor.</p>
                                            <br />
                                            <ol>
                                                <li>
                                                    <b>Start Posten</b>
                                                    <br />
                                                    <span>
                                                        Når I ankommer til en post, vil I i VM LIVE kunne se posten i
                                                        oversigten. <br />
                                                        Klik på posten og tryk på ”GÅ I KØ” knappen. Postmandskabet
                                                        råber jer nu op når det er jeres tur.
                                                        <br />
                                                    </span>
                                                </li>
                                                <li>
                                                    <b>Løs Posten</b>
                                                    <br />
                                                    <span>
                                                        Postmandskabet vil nu instruere jer i postens indhold, og I
                                                        løser posten på bedste vis.
                                                    </span>
                                                </li>
                                                <li>
                                                    <b>Point og Feedback</b>
                                                    <br />
                                                    <span>
                                                        Postmandskabet giver jer point, når posten er løst.
                                                        <br />
                                                        De indsender pointene i deres APP så her skal I som patrulje
                                                        ikke gøre noget. I vil i jeres app nu kunne se at posten er
                                                        løst.
                                                    </span>
                                                </li>
                                            </ol>

                                            <p>
                                                Den aktuelle varighed beregnes ud fra, hvor mange patruljer der er på
                                                posten lige nu, og hvad den gennemsnitlige varighed på posten er.
                                                Varigheden på en post er fra den startes til den sluttes. I vil kunne
                                                bruge dette til at planlægge, hvor I tager hen næste gang.
                                            </p>
                                            <br />
                                            <b>VIGTIGT:</b>
                                            <p>
                                            </p>
                                            <p>
                                                I kan godt stille jer i kø til flere poster på én gang. Er I ikke
                                                tilstede når Postmandskabet kalder på jer, så vil de melde jer ud af
                                                køen, så I igen skal træde ind bagerst i køen for at få lov at løse
                                                posten.
                                            </p>
                                            <p>
                                                <br />
                                            </p>
                                            <p>
                                                Hvis I trykker på "FORLAD KØ", så træder i selv ud af køen og kan ikke
                                                løse posten, før I igen har trykket "GÅ I KØ"
                                            </p>
                                        </div>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel className="informationExpansion">
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography className={classes.heading}>Tjek ind og tjek ud</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <div>
                                            <p>
                                                Når I forlader pladsen om morgenen for at tage ud på posterne, skal I
                                                tjekke ud. Det sker i receptionen, hvor I får udleveret patruljens
                                                patrulje-kort. I vil også skulle oplyse det samlede antal deltagere på
                                                patruljen inkl. ledere/assistenter.
                                                <br />
                                                Når I kommer tilbage til lejrpladsen om aftenen, skal I tjekke ind ved
                                                at aflevere patrulje-kortet. Samtidig får I udleveret materialer og
                                                kapitelkort til næste dags oplevelser så I kan bruge aftenen på at
                                                forberede jer.
                                                <br />
                                                <br />
                                                <br />
                                                Receptionen åbner kl. 06:00 og er bemandet hele dagen både fysisk og
                                                telefonisk. Telefonnummeret fremgår af jeres patrulje patrulje-kort og
                                                øverst på denne side.
                                            </p>
                                        </div>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel className="informationExpansion">
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography className={classes.heading}>Transport</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <div>
                                            <p>
                                                Det Travelpass som I har fået udleveret ved check ind på pladsen kan I bruge til at rejse 
                                                med bus, tog og metro i hele hovedstadsområdet frem til og med lørdag nat kl. 04.00.
                                                <br />
                                                <br />
                                                På <a href="https://maps.google.com/">Google Maps</a> eller{' '}
                                                <a href="https://rejseplanen.dk/">rejseplanen</a> har i mulighed for at
                                                planlægge jeres rute igennem VM. Vær dog opmærksom på at det kun er rejseplanen der har live oplysninger om trafik driften, som fx om et tog er aflyst.
                                                <br />
                                            </p>
                                        </div>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                                <ExpansionPanel className="informationExpansion">
                                    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
                                        <Typography className={classes.heading}>Vand</Typography>
                                    </ExpansionPanelSummary>
                                    <ExpansionPanelDetails>
                                        <div>
                                            <p>
                                                Husk at drikke en masse vand. I kan se på posternes beskrivelse om der
                                                er mulighed for at fylde vand , og derudover har vi et kort over{' '}
                                                <a
                                                    href="https://www.google.com/maps/d/u/0/viewer?ll=55.678746000000025%2C12.558745999999928&spn=0.100466%2C0.177841&t=m&msa=0&z=12&source=embed&ie=UTF8&mid=1Dg2I4haKMmjda6lw5F5ZTHf010g"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    offentlige vandposter i Københavns Kommune.
                                                </a>
                                            </p>
                                        </div>
                                    </ExpansionPanelDetails>
                                </ExpansionPanel>
                            </TabContainer>
                        )}
                        {value === 1 && (
                            <TabContainer>
                                <Paper>
                                    <Teltpladser />
                                </Paper>
                            </TabContainer>
                        )}
                        {value === 2 && (
                            <TabContainer>
                                <Paper>
                                    <List className={classes.list} subheader={<li />} disablePadding={true}>
                                        {this.state.program.map(dag => {
                                            return (
                                                <li key={`${dag.navn}`} className={classes.listSection}>
                                                    <ul className={classes.ul}>
                                                        <ListSubheader
                                                            className={classes.listSubheader}
                                                        >{`${dag.navn}`}</ListSubheader>
                                                        {dag.programpunkter.map(programpunkt => {
                                                            return (
                                                                <div key={`${dag.navn}-${programpunkt.tidspunkt}`}>
                                                                    <ListItem>
                                                                        <ListItemText
                                                                            primary={
                                                                                programpunkt.tidspunkt
                                                                                    ? `${programpunkt.tidspunkt} - ${programpunkt.tekst}`
                                                                                    : programpunkt.tekst
                                                                            }
                                                                        />
                                                                    </ListItem>
                                                                    <Divider />
                                                                </div>
                                                            );
                                                        })}
                                                    </ul>
                                                </li>
                                            );
                                        })}
                                    </List>
                                </Paper>
                            </TabContainer>
                        )}
                    </Grid>
                </Grid>
            </div>
        );
    }
}

SimpleTabs.propTypes = {
    classes: PropTypes.object.isRequired,
};
function mapStateToProps(state) {
    return {
        aar: state.sessionInfo.aar,
    };
}

export default connect(mapStateToProps)(withStyles(styles)(SimpleTabs));
