import { CLEAR_CACHE } from '../actions/cache';
import { RECEIVE_TELTPLADSER } from '../actions/teltpladser';
function reducer(state = null, action) {
    switch (action.type) {
        case RECEIVE_TELTPLADSER:
            return action.teltpladser;
        case CLEAR_CACHE:
            return null;
        default:
            return state;
    }
}

export default reducer;
