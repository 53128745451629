import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import Button from 'material-ui/Button';
import Paper from 'material-ui/Paper';
import Grid from 'material-ui/Grid';
import TextField from 'material-ui/TextField';
import { invalidateActivation } from './actions/activated';
import { connect } from 'react-redux';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing.unit * 2,
    },
    videreButton: {
        marginTop: theme.spacing.unit * 2,
    },
    aktiverButton: {
        marginTop: theme.spacing.unit * 2,
        backgroundColor: theme.palette.success.main,
    },
    gensendButton: {
        marginTop: theme.spacing.unit * 2,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.text.primary,
    },
    annullerButton: {
        marginTop: theme.spacing.unit * 2,
        backgroundColor: theme.palette.danger.main,
    },
    paper: {
        padding: theme.spacing.unit * 2,
        textAlign: 'center',
        color: theme.palette.text.secondary,
    },
    divider: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit,
    },
});

class SendSMS extends React.Component {
    constructor() {
        super();
        this.state = {
            text: '',
        };
        this.handleChange = this.handleChange.bind(this);
    }

    sendSMS = () => {
        if (this.state.text === '') {
            alert('Beskeden er tom. Prøv igen');
        } else {
            fetch(
                `${process.env.REACT_APP_API_BASE_URL}/mobileapi/send_besked?message=${this.state.text}&patrulje_token=${this.props.token
                }&_=${new Date().getTime()}`,
                {
                    method: 'POST'
                }
            )
                .then(function (response) {
                    return response.json();
                })
                .then(response => {
                    if (response.status === 'NO_PATRULJE') {
                        this.props.dispatch(invalidateActivation());
                    } else if (response.status === 'OK') {
                        alert('Beskeden er sendt til SMS-systemet. Tjek din SMS inbox for svar.');
                        this.setState({ text: '' });
                    }
                });
        }
    };

    handleChange(evt) {
        this.setState({ [evt.target.name]: evt.target.value });
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Grid container justify="center">
                    <Grid item xs={12} sm={12} md={9} lg={6}>
                        <Paper className={classes.paper}>
                            <form className={classes.form} noValidate autoComplete="off" onSubmit={this.sendStepOne}>
                                <TextField
                                    name="text"
                                    onChange={this.handleChange}
                                    value={this.state.text}
                                    id="text"
                                    label="SMS tekst"
                                    multiline
                                    rows="4"
                                    fullWidth
                                />
                                <Button
                                    className={classes.videreButton}
                                    variant="raised"
                                    fullWidth={true}
                                    onClick={this.sendSMS}
                                    disabled={this.state.text.length === 0}
                                >
                                    Send SMS
                                </Button>
                            </form>
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

SendSMS.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    return {
        token: state.token,
    };
}

export default connect(mapStateToProps)(withStyles(styles)(SendSMS));
