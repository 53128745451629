import { RECEIVE_POSTER, RECEIVE_POST } from '../actions/poster';
import { INVALIDATE_ACTIVATION } from '../actions/activated';
import { CLEAR_CACHE } from '../actions/cache';

function reducer(state = null, action) {
    switch (action.type) {
        case RECEIVE_POSTER:
            return action.poster;
        case RECEIVE_POST:
            return state.map(post => (post.id === action.post.id ? action.post : post));
        case INVALIDATE_ACTIVATION:
            return null;
        case CLEAR_CACHE:
            return null;
        default:
            return state;
    }
}

export default reducer;
