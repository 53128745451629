import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import Paper from 'material-ui/Paper';
import Typography from 'material-ui/Typography';
import Grid from 'material-ui/Grid';
import { connect } from 'react-redux';
import Chip from 'material-ui/Chip';
import { green } from 'material-ui/colors';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from 'material-ui/Button';

const styles = theme => ({
    root: {
        padding: theme.spacing.unit * 2,
    },
    paper: {
        padding: theme.spacing.unit * 2,
    },
    badgeImg: {
        width: '100%',
    },
    chipLevel: {
        margin: theme.spacing.unit * 1,
        backgroundColor: green[700],
        color: green[50],
        position: 'absolute',
        fontSize: 18,
    },
});

class Badge extends React.Component {
    constructor() {
        super();
        this.state = {
            badge: null,
        };

        this.goBack = this.goBack.bind(this);
    }

    fetchBadge(id) {
        var badge = this.props.badges.filter(b => b.id.toString() === id)[0];
        this.setState({
            badge: badge,
        });
    }

    componentDidMount() {
        this.fetchBadge(this.props.match.params.id);
    }

    goBack() {
        this.props.history.push(`/badges`);
    }

    render() {
        const { classes } = this.props;
        const { badge } = this.state;
        return (
            <div className={classes.root}>
                <Grid container justify="center">
                    <Grid item xs={12} sm={12} md={9} lg={6}>
                        <Paper className={classes.paper}>
                            <Button variant="flat" size="small" onClick={this.goBack}>
                                <ArrowBackIcon />
                                Tilbage
                            </Button>
                            {this.state.badge ? (
                                <div>
                                    <Typography variant="headline" align="left">
                                        {this.state.badge.title}
                                    </Typography>
                                    <Grid container justify="center" spacing={24}>
                                        <Grid item xs={6} sm={5} md={4} lg={3}>
                                            {this.state.badge.has_badge &&
                                                this.state.badge.level && (
                                                    <Chip
                                                        label={this.state.badge.level}
                                                        className={classes.chipLevel}
                                                    />
                                                )}
                                                <div
                                                    className={classes.badgeImg}
                                                    style={
                                                        !badge.has_badge
                                                            ? {
                                                                    filter: 'grayscale(100%)',
                                                                    opacity: 0.5,
                                                                    height: 150,
                                                                    width: 150,
                                                                    backgroundImage: `url(/images/badges/${badge.short_name}.svg)`,
                                                                    backgroundSize: '150px',
                                                                    backgroundRepeat: 'no-repeat',
                                                                    backgroundPosition: 'center',
                                                                    border: '4px solid black',
                                                                    borderRadius: '100%'
                                                                }
                                                            : {
                                                                height: 150,
                                                                width: 150,
                                                                backgroundImage: `url(/images/badges/${badge.short_name}.svg)`,
                                                                backgroundSize: '150px',
                                                                backgroundRepeat: 'no-repeat',
                                                                backgroundPosition: 'center',
                                                                border: '4px solid black',
                                                                borderRadius: '100%'
                                                            }
                                                    }
                                                    alt={badge.title}
                                                ></div>
                                            {!this.state.badge.has_badge ? (
                                                <Typography align="center">
                                                    Patruljen har ikke fået dette badge endnu
                                                </Typography>
                                            ) : (
                                                <Typography align="center">
                                                    Patruljen har fået dette badge i niveau {this.state.badge.level}
                                                </Typography>
                                            )}
                                        </Grid>
                                        <Grid item xs={6} sm={7} md={8} lg={9}>
                                            <Typography
                                                dangerouslySetInnerHTML={{ __html: this.state.badge.description }}
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                            ) : (
                                <div />
                            )}
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

Badge.propTypes = {
    classes: PropTypes.object.isRequired,
};
function mapStateToProps(state) {
    return {
        badges: state.badges,
    };
}

export default connect(mapStateToProps)(withStyles(styles)(Badge));
