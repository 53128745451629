import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import { Chip, Paper } from 'material-ui';
import Typography from 'material-ui/Typography';
import { connect } from 'react-redux';
import Grid from 'material-ui/Grid';
import Button from 'material-ui/Button';
import { postCheckin, postCheckout, fetchPost } from './actions/poster';
import CheckIcon from '@material-ui/icons/Check';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { green, red } from 'material-ui/colors';
import { withRouter } from 'react-router-dom';
import Opgave from './Opgave';

const styles = theme => ({
    root: {
        padding: theme.spacing.unit * 2,
    },
    paper: {
        padding: theme.spacing.unit * 2,
    },
    buttonNoColor: {},
    checkInButton: {
        color: green[50],
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
        '&:disabled': {
            color: green[700],
        },
    },
    checkOutButton: {
        color: red[50],
        backgroundColor: red[500],
        '&:hover': {
            backgroundColor: red[700],
        },
        '&:disabled': {
            color: red[700],
        },
    },
    postbeskrivelseButton: {
        backgroundColor: '#F0BF06',
        marginTop: theme.spacing.unit * 2,
        '&:hover': {
            backgroundColor: '#F0BF06',
        },
    },
    marginTop: {
        marginTop: theme.spacing.unit * 2,
    },
    checkInIcon: {
        color: green[500],
    },
    chipQueue: {
        color: 'white',
        backgroundColor: '#326295',
        marginTop: theme.spacing.unit * 2,
    },
});

class Post extends React.Component {
    constructor() {
        super();
        this.state = {
            post: null,
        };

        this.goBack = this.goBack.bind(this);
    }

    fetchThisPost(id) {
        this.props.dispatch(
            fetchPost(
                this.props.token,
                this.props.position.lat,
                this.props.position.lng,
                this.props.position.accuracy,
                id,
            ),
        );
        // var post = this.props.poster.filter(p => p.id.toString() === id)[0];
        // this.setState({
        //     post: post,
        // });
    }

    checkIn = () => {
        this.props.dispatch(
            postCheckin(
                this.props.token,
                this.props.position.lat,
                this.props.position.lng,
                this.props.position.accuracy,
                this.state.post.id,
            ),
        );
    };

    checkOut = () => {
        this.props.dispatch(
            postCheckout(
                this.props.token,
                this.props.position.lat,
                this.props.position.lng,
                this.props.position.accuracy,
                this.state.post.id,
            ),
        );
    };

    componentWillReceiveProps(nextProps, prevState) {
        if (nextProps.poster) {
            var post = nextProps.poster.filter(p => p.id.toString() === this.props.match.params.id)[0];
            this.setState({
                post: post,
            });
        }
    }

    componentDidMount() {
        this.fetchThisPost(this.props.match.params.id);
    }

    goBack() {
        this.props.history.push(`/`);
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <Grid container justify="center">
                    <Grid item xs={12} sm={12} md={9} lg={6}>
                        <Paper className={classes.paper}>
                            <Button variant="flat" size="small" onClick={this.goBack}>
                                <ArrowBackIcon />
                                Tilbage
                            </Button>
                            {this.state.post ? (
                                <div>
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <div style={{ position: 'relative', marginBottom: 16 }}>
                                            <Typography
                                                variant="headline"
                                                align="center"
                                                style={{ position: 'absolute', color: 'white', top: 24, width: '100%' }}
                                            >
                                                {this.state.post.betegnelse_maplabel}
                                            </Typography>
                                            <img
                                                alt={this.state.post.map_marker_image}
                                                src={`/images/icons/${this.state.post.map_marker_image}`}
                                                height={100}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        {this.state.post.info && (
                                            <Typography
                                                align="left"
                                                id="postInfo"
                                                style={{ marginBottom: 10 }}
                                                dangerouslySetInnerHTML={{ __html: this.state.post.info }}
                                            />
                                        )}
                                        {this.state.post.specialistkategori_navn && <div>
                                            <Typography align="left">
                                                <b>Specialistkategori:</b> {this.state.post.specialistkategori_navn}
                                            </Typography>
                                            <br />
                                        </div>}
                                        <Typography align="left">
                                            <b>Afstand til post:</b>{' '}
                                            {this.state.post.distance <= 1000
                                                ? `${this.state.post.distance} m`
                                                : `${(this.state.post.distance / 1000)
                                                    .toFixed(1)
                                                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',')} km`}
                                        </Typography>
                                        {this.state.post.queue_enabled && <div>
                                            {this.state.post.queue_status !== "in_queue" && <Typography align="left">
                                                <b>Estimeret køtid:</b> {this.state.post.ventetid}
                                            </Typography>}
                                            <Typography align="left">
                                                <b>Antal patruljer i kø:</b> {this.state.post.antal_patruljer}
                                            </Typography>
                                        </div>}
                                        {this.state.post.queue_status === "in_queue" && this.state.post.embed_opgave_id &&
                                            <Grid item xs={12} >
                                                <Opgave id={this.state.post.embed_opgave_id} checksum={this.state.post.embed_opgave_id_checksum} />
                                            </Grid>}
                                        {this.state.post.patrulje_queue_position && this.state.post.postbeskrivelse_url &&
                                            <Grid item xs={12}>
                                                <a target="_blank" href={this.state.post.postbeskrivelse_url} style={{ textDecoration: "none" }}>
                                                    <Button
                                                        className={classes.postbeskrivelseButton}
                                                        variant="raised"
                                                        fullWidth={true}
                                                    >
                                                        Vis postbeskrivelsen
                                                    </Button>
                                                </a>
                                            </Grid>}
                                        {this.state.post.patrulje_queue_position &&
                                            <Grid container justify="center">
                                                <Chip label={<div>I er nummer <b>{this.state.post.patrulje_queue_position}</b> i køen</div>} className={classes.chipQueue} />
                                            </Grid>}
                                    </div>
                                    {!this.state.post.embed_opgave_id && (this.state.post.stop_button_text || this.state.post.start_button_text) && <Grid
                                        container
                                        justify="center"
                                        spacing={16}
                                        className={classes.marginTop}
                                    >
                                        <Grid item xs={6}>
                                            {this.state.post.start_button_text && <Button
                                                className={
                                                    this.state.post.is_ankomst || this.state.post.checkin_radius_ok
                                                        ? classes.checkInButton
                                                        : classes.buttonNoColor
                                                }
                                                variant="raised"
                                                fullWidth={true}
                                                onClick={this.checkIn}
                                                disabled={!this.state.post.allow_start}
                                            >
                                                {this.state.post.queue_status === "in_queue" && <CheckIcon />} {this.state.post.start_button_text}
                                            </Button>}
                                        </Grid>
                                        <Grid item xs={6}>
                                            {this.state.post.stop_button_text && <Button
                                                className={
                                                    this.state.post.is_tjekud || this.state.post.checkin_radius_ok
                                                        ? classes.checkOutButton
                                                        : classes.buttonNoColor
                                                }
                                                variant="raised"
                                                fullWidth={true}
                                                onClick={this.checkOut}
                                                disabled={!this.state.post.allow_stop}
                                            >
                                                {this.state.post.stop_button_text}
                                            </Button>}
                                        </Grid>
                                    </Grid>}
                                    {this.state.post.queue_status === "in_progress" && <Typography align="center" className={classes.marginTop}>
                                        <b>I er igang med at løse posten</b>
                                    </Typography>}
                                    {this.state.post.queue_status === "completed" && <Typography align="center" className={classes.marginTop}>
                                        <CheckIcon
                                            className={
                                                classes.checkInIcon
                                            }
                                        />
                                        <b>I har gennemført posten</b>
                                    </Typography>}
                                    {/* <Typography align="left"
                                        className={classes.marginTop}>
                                        <a
                                            href={`http://www.rejseplanen.dk/bin/query.exe/mn?S=${
                                                this.props.position.lat
                                            }+${this.props.position.lng}&Z=${this.state.post.lat}+${
                                                this.state.post.lng
                                            }&SALL=1&ZALL=1&start=yes`}
                                            target="_blank"
                                            rel="no-follow noopener noreferrer"
                                        >
                                            Find med rejseplanen
                                        </a>
                                    </Typography> */}
                                </div>
                            ) : null}
                        </Paper>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

Post.propTypes = {
    classes: PropTypes.object.isRequired,
};
function mapStateToProps(state) {
    return {
        poster: state.poster,
        position: state.position.position,
        token: state.token,
    };
}

export default connect(mapStateToProps)(withRouter(withStyles(styles)(Post)));
