import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import { connect } from 'react-redux';
import { connectToSpreadsheet } from 'react-google-sheet-connector';
import { Typography } from 'material-ui';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing.unit * 2,
    },
});
class Sheets extends React.Component {
    constructor() {
        super();
        this.state = {
            patruljer: [],
            patrulje: null,
            error: '',
            max: 0,
        };
    }

    getData() {
        const seniorer = [];
        const vaebnere = [];
        this.props.data.forEach((row, i) => {
            if (i >= 1 && i <= 10) {
                vaebnere.push(row);
            }
            if (i >= 13 && i <= 22) {
                seniorer.push(row);
            }
        });
        console.log(vaebnere);
        if (vaebnere.filter(v => v[0] === this.props.patruljeId).length === 1) {
            this.setState({
                patrulje: vaebnere.filter(v => v[0] === this.props.patruljeId)[0],
                patruljer: vaebnere,
                max: vaebnere.reduce((prev, current) => (prev > current[2] ? prev : current[2]))[2],
            });
        } else if (seniorer.filter(v => v[0] === this.props.patruljeId).length === 1) {
            this.setState({
                patrulje: seniorer.filter(v => v[0] === this.props.patruljeId)[0],
                patruljer: seniorer,
                max: seniorer.reduce((prev, current) => (prev > current[2] ? prev : current[2]))[2],
            });
        } else {
            this.setState({
                error: 'Patruljen kan ikke findes på listen',
            });
        }
    }

    componentDidMount() {
        console.log(this.props);
        this.getData();
    }

    render() {
        return (
            <div
                style={{
                    backgroundColor: this.state.patrulje
                        ? this.state.patrulje[2] === '0'
                            ? '#D32F2F'
                            : 'white'
                        : 'white',
                    padding: 16,
                }}
            >
                {this.state.error ? (
                    <Typography variant="title">{this.state.error}</Typography>
                ) : this.state.patruljer.length > 0 ? (
                    !this.state.patruljer.filter(p => p[2] > 0).length > 0 ? (
                        <Typography variant="title" style={{ color: 'white' }}>
                            Løbet er slut
                        </Typography>
                    ) : (
                        <div>
                            <Typography variant="title">Jeres resterende tid</Typography>
                            <Typography variant="display3">
                                {this.state.patrulje ? this.state.patrulje[1] : null}
                            </Typography>
                            <Typography variant="title">Alles resterende tid</Typography>
                            <table style={{ width: '100%' }} cellSpacing="10">
                                <tbody>
                                    {this.state.patruljer.map(p => (
                                        <tr key={p[0]}>
                                            <td style={{ width: 50, textAlign: 'right' }}>
                                                <Typography>{p[0]}</Typography>
                                            </td>
                                            <td style={{ position: 'relative' }}>
                                                <div style={{ width: '100%' }}>
                                                    <div
                                                        style={{
                                                            width: `${p[2] / this.state.max * 100}%`,
                                                            backgroundColor: '#326295',
                                                            height: 25,
                                                        }}
                                                    />
                                                </div>
                                                <div style={{ position: 'absolute', top: 3, left: 10 }}>
                                                    <Typography
                                                        style={{
                                                            color: 'white',
                                                            textShadow:
                                                                '-1px 0 black, 0 1px black, 1px 0 black, 0 -1px black',
                                                        }}
                                                    >
                                                        {p[1]}
                                                    </Typography>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )
                ) : (
                    <Typography variant="title" style={{ color: 'white' }}>
                        Det ser ikke ud til at du har noget at gøre her
                    </Typography>
                )}
            </div>
        );
    }
}

Sheets.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    return {
        token: state.token,
        patruljeId: state.sessionInfo.patruljeData.pkort,
    };
}

export default connect(mapStateToProps)(withStyles(styles)(connectToSpreadsheet(Sheets)));
