import { invalidateActivation } from './activated';
import { receiveSessionInfo } from './login';
export const REQUEST_OPGAVE = 'REQUEST_OPGAVE';
function requestOpgave() {
    return {
        type: REQUEST_OPGAVE,
    };
}

export const RECEIVE_OPGAVE = 'RECEIVE_OPGAVE';
export function receiveOpgave(opgave) {
    return {
        type: RECEIVE_OPGAVE,
        opgave: opgave,
        receivedAt: Date.now(),
    };
}

export const ERROR_OPGAVE = 'ERROR_OPGAVE';
export function errorOpgave(error) {
    return {
        type: ERROR_OPGAVE,
        error: error,
        receivedAt: Date.now(),
    };
}

export function fetchOpgave(opgave_id, checksum) {
    return (dispatch, getState) => {
        // grab current state
        const state = getState();

        dispatch(requestOpgave());
        return fetch(
            `${process.env.REACT_APP_API_BASE_URL}/mobileapi/opgave?opgave_id=${opgave_id}&checksum=${checksum}&patrulje_token=${state.token}&_=${new Date().getTime()}`,
        )
            .then(res => {
                return res.json();
            })
            .then(json => {
                if (json.status === 'NO_PATRULJE') {
                    dispatch(invalidateActivation());
                } else if (json.status === 'INVALID_CHECKSUM') {
                    dispatch(errorOpgave('Den opgave du søger findes ikke'));
                } else if (json.status === 'NO_ACCESS') {
                    dispatch(errorOpgave('Opgaven kunne ikke hentes'));
                } else {
                    dispatch(receiveOpgave(json.opgave));
                    dispatch(receiveSessionInfo(json.sessionInfo));
                }
            });
    };
}

export function svarTekstOpgave(opgave_id, checksum, svar) {
    return (dispatch, getState) => {
        // grab current state
        const state = getState();

        return fetch(
            `${process.env.REACT_APP_API_BASE_URL}/mobileapi/opgave_svar?opgave_id=${opgave_id}&answer_text=${svar}&checksum=${checksum}&patrulje_token=${state.token}&_=${new Date().getTime()}`, {
            method: 'POST'
        })
            .then(res => {
                return res.json();
            })
            .then(json => {
                if (json.status === 'NO_PATRULJE') {
                    dispatch(invalidateActivation());
                } else if (json.status === 'CLOSED_FOR_ANSWERS') {
                    dispatch(fetchOpgave(opgave_id, checksum));
                } else {
                    dispatch(receiveOpgave(json.opgave));
                    dispatch(receiveSessionInfo(json.sessionInfo));
                }
            });
    };
}

export function svarFotoOpgave(opgave_id, checksum, file) {
    return (dispatch, getState) => {
        // grab current state
        const state = getState();


        const formData = new FormData();
        formData.append("answer_file", file)

        return fetch(
            `${process.env.REACT_APP_API_BASE_URL}/mobileapi/opgave_svar?opgave_id=${opgave_id}&checksum=${checksum}&patrulje_token=${state.token}&_=${new Date().getTime()}`, {
            method: 'POST',
            body: formData
        })
            .then(res => {
                return res.json();
            })
            .then(json => {
                if (json.status === 'NO_PATRULJE') {
                    dispatch(invalidateActivation());
                } else if (json.status === 'CLOSED_FOR_ANSWERS') {
                    dispatch(fetchOpgave(opgave_id, checksum));
                } else {
                    dispatch(receiveOpgave(json.opgave));
                    dispatch(receiveSessionInfo(json.sessionInfo));
                }
            });
    };
}

export function fortrydSvarOpgave(opgave_id, checksum) {
    return (dispatch, getState) => {
        // grab current state
        const state = getState();

        return fetch(
            `${process.env.REACT_APP_API_BASE_URL}/mobileapi/opgave_cancel_svar?opgave_id=${opgave_id}&checksum=${checksum}&patrulje_token=${state.token}&_=${new Date().getTime()}`, {
            method: 'POST',
        })
            .then(res => {
                return res.json();
            })
            .then(json => {
                if (json.status === 'NO_PATRULJE') {
                    dispatch(invalidateActivation());
                } else if (json.status === 'CLOSED_FOR_ANSWERS') {
                    dispatch(fetchOpgave(opgave_id, checksum));
                } else {
                    dispatch(receiveOpgave(json.opgave));
                    dispatch(receiveSessionInfo(json.sessionInfo));
                }
            });
    };
}
