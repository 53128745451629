import { receiveSessionInfo, setLoginError } from './login';
export const REQUEST_ACTIVATION = 'REQUEST_ACTIVATION';
function requestActivation() {
    return {
        type: REQUEST_ACTIVATION,
    };
}

export const RECEIVE_ACTIVATION = 'RECEIVE_ACTIVATION';
function receiveActivation(activated) {
    return {
        type: RECEIVE_ACTIVATION,
        activated: activated,
        receivedAt: Date.now(),
    };
}

export const INVALIDATE_ACTIVATION = 'INVALIDATE_ACTIVATION';
export function invalidateActivation() {
    return { type: INVALIDATE_ACTIVATION };
}

function fetchActivation(state, aktiveringskode) {
    return dispatch => {
        dispatch(requestActivation());
        return fetch(
            `${process.env.REACT_APP_API_BASE_URL}/mobileapi/activate_patrulje?activation_key=${aktiveringskode}&patrulje_token=${state.token
            }&_=${new Date().getTime()}`,
            {
                method: 'POST'
            }
        )
            .then(res => {
                return res.json();
            })
            .then(json => {
                if (json.status === 'NO_PATRULJE') {
                    dispatch(invalidateActivation());
                } else if (json.status === 'WRONG_ACTIVATION_KEY') {
                    dispatch(setLoginError('Aktiveringskoden er forkert - Prøv igen'));
                } else {
                    if (json.status === 'OK') {
                        dispatch(receiveActivation(true));
                        dispatch(receiveSessionInfo(json.sessionInfo));
                    }
                }
            });
    };
}

function shouldFetchActivation(state) {
    const activated = state.activated;
    return !activated;
}

export function fetchActivationIfNeeded(aktiveringskode) {
    // Note that the function also receives getState()
    // which lets you choose what to dispatch next.

    // This is useful for avoiding a network request if
    // a cached value is already available.

    return (dispatch, getState) => {
        if (shouldFetchActivation(getState())) {
            // Dispatch a thunk from thunk!
            return dispatch(fetchActivation(getState(), aktiveringskode));
        } else {
            // Let the calling code know there's nothing to wait for.
            return Promise.resolve();
        }
    };
}
