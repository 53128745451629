import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import Button from 'material-ui/Button';
import Paper from 'material-ui/Paper';
import Grid from 'material-ui/Grid';
import { Typography, CircularProgress, Divider, TextField, Chip, RadioGroup, FormControlLabel, Radio } from 'material-ui';
import { connect } from 'react-redux';
import { fetchOpgave, svarTekstOpgave, svarFotoOpgave, fortrydSvarOpgave } from './actions/opgave';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { withRouter } from 'react-router-dom';
import { green } from 'material-ui/colors';

const styles = theme => ({
    root: {
        flexGrow: 1,
        padding: theme.spacing.unit * 2,
    },
    title: {
        marginBottom: theme.spacing.unit
    },
    marginTop: {
        marginTop: theme.spacing.unit * 2
    },
    videreButton: {
        marginTop: theme.spacing.unit * 2,
    },
    aktiverButton: {
        marginTop: theme.spacing.unit * 2,
        backgroundColor: theme.palette.success.main,
    },
    chipBlue: {
        backgroundColor: theme.palette.primary.main,
        color: '#ffffff'
    },
    chipWhite: {
        backgroundColor: '#ffffff',
    },
    button: {
        marginTop: theme.spacing.unit * 2,
    },
    form: {
        marginTop: theme.spacing.unit * 2,
    },
    paper: {
        padding: theme.spacing.unit * 2,
        color: theme.palette.text.secondary,
    },
    paperGreen: {
        padding: theme.spacing.unit * 2,
        backgroundColor: green[500],
        marginBottom: theme.spacing.unit * 2
    },
    divider: {
        marginTop: theme.spacing.unit * 3,
        marginBottom: theme.spacing.unit,
    },
    emptyList: {
        marginTop: theme.spacing.unit * 3,
    }
});

class Opgave extends React.Component {
    constructor() {
        super();
        this.state = {
            tekstSvar: '',
            raekkefoelge: [],
            imageFile: null
        };
        this.handleChange = this.handleChange.bind(this);
        this.goBack = this.goBack.bind(this);
        this.addToRaekkefoelge = this.addToRaekkefoelge.bind(this);
        this.removeFromRaekkefoelge = this.removeFromRaekkefoelge.bind(this);
        this.selectImage = this.selectImage.bind(this);
        this.fortrydSvar = this.fortrydSvar.bind(this);
    }

    componentDidMount() {
        this.fetchOpgave();
    }

    sendTekstSvar = event => {
        event.preventDefault();
        this.props.dispatch(svarTekstOpgave(this.props.id ? this.props.id : this.props.match.params.id, this.props.checksum ? this.props.checksum : this.props.match.params.checksum, this.state.tekstSvar));
    }

    sendRaekkefoelgeSvar = event => {
        event.preventDefault();
        this.props.dispatch(svarTekstOpgave(this.props.id ? this.props.id : this.props.match.params.id, this.props.checksum ? this.props.checksum : this.props.match.params.checksum, this.state.raekkefoelge.join(',')));
    }

    sendFotoSvar = event => {
        event.preventDefault();
        this.props.dispatch(svarFotoOpgave(this.props.id ? this.props.id : this.props.match.params.id, this.props.checksum ? this.props.checksum : this.props.match.params.checksum, this.state.imageFile));
    }

    fortrydSvar() {
        this.props.dispatch(fortrydSvarOpgave(this.props.id ? this.props.id : this.props.match.params.id, this.props.checksum ? this.props.checksum : this.props.match.params.checksum));
        this.setState({
            tekstSvar: '',
            raekkefoelge: [],
            imageFile: null
        });
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    }

    addToRaekkefoelge(o) {
        this.setState({ raekkefoelge: this.state.raekkefoelge.concat(o) });
    }

    removeFromRaekkefoelge(o) {
        this.setState({ raekkefoelge: this.state.raekkefoelge.filter(r => r !== o) });
    }

    fetchOpgave() {
        this.props.dispatch(fetchOpgave(this.props.id ? this.props.id : this.props.match.params.id, this.props.checksum ? this.props.checksum : this.props.match.params.checksum));
    }

    selectImage = e => {
        //const files = Array.from(e.target.files)
        this.setState({ imageFile: e.target.files[0] })
    }

    goBack() {
        this.props.history.goBack();
    }

    render() {
        const { classes, opgave } = this.props;
        const { tekstSvar, raekkefoelge, imageFile } = this.state;
        return (
            <div className={classes.root} style={this.props.id && { padding: 0 }}>
                {opgave && <div>
                    {opgave.state === "error" &&
                        <Grid container justify="center">
                            <Grid item xs={12} sm={12} md={9} lg={6}>
                                <Paper className={classes.paper}>
                                    <Typography
                                        align="left"
                                    >{opgave.error}</Typography>
                                </Paper>
                            </Grid>
                        </Grid>
                    }
                    {opgave.state === "loading" &&
                        <Grid container direction="row"
                            justify="center"
                            alignItems="center"><CircularProgress className={classes.progress} /></Grid>
                    }
                    {opgave.state === "loaded" && opgave.opgave &&
                        <div>
                            {opgave.opgave.answered_text && <Grid container justify="center">
                                <Grid item xs={12} sm={12} md={9} lg={6}>
                                    <Paper className={classes.paperGreen}>
                                        <Typography
                                            align="left"
                                            style={{ color: '#ffffff' }}
                                            dangerouslySetInnerHTML={{ __html: opgave.opgave.answered_text }}
                                        />
                                    </Paper>
                                </Grid>
                            </Grid>}
                            <Grid container justify="center">
                                <Grid item xs={12} sm={12} md={9} lg={6}>
                                    <Paper className={classes.paper} elevation={this.props.id ? 0 : 3} style={this.props.id && { paddingLeft: 0, paddingRight: 0 }}>
                                        {!this.props.id && <Button variant="flat" size="small" onClick={this.goBack}>
                                            <ArrowBackIcon />
                                            Tilbage
                                        </Button>}
                                        {opgave.opgave &&
                                            <div>
                                                <Typography variant="headline" align="left">
                                                    {opgave.opgave.headline}
                                                </Typography>
                                                {opgave.opgave.specialistkategori_navn && <div>
                                                    <Typography align="left">
                                                        <b>Specialistkategori:</b> {opgave.opgave.specialistkategori_navn}
                                                    </Typography>
                                                    <br />
                                                </div>}
                                                <Typography
                                                    align="left"
                                                    id="opgaveTekst"
                                                    style={{ marginBottom: 10 }}
                                                    dangerouslySetInnerHTML={{ __html: opgave.opgave.html_text }}
                                                />
                                                {opgave.opgave.attachment_url && <div>
                                                    {['jpg', 'png', 'jpeg', 'gif', 'svg', 'webp'].includes(opgave.opgave.attachment_url.split('.').pop())
                                                        ? <img src={opgave.opgave.attachment_url} style={{ width: '100%' }} alt="Opgave billede" />
                                                        : <Typography align="left" style={{ marginBottom: '10px' }}><a href={opgave.opgave.attachment_url} target="_blank">Klik for at åbne fil</a></Typography>
                                                    }
                                                </div>}
                                                <Divider />
                                                {!opgave.opgave.is_answered && opgave.opgave.is_open && <div>
                                                    {opgave.opgave.question_type === "tekst" &&
                                                        <form className={classes.form} noValidate autoComplete="off" onSubmit={this.sendTekstSvar}>
                                                            <TextField
                                                                name="tekstSvar"
                                                                onChange={this.handleChange}
                                                                value={tekstSvar}
                                                                id="tekstSvar"
                                                                type="text"
                                                                label="Svar"
                                                                fullWidth
                                                            />
                                                            <Button
                                                                className={classes.button}
                                                                type="submit"
                                                                variant="raised"
                                                                fullWidth
                                                                disabled={!(tekstSvar.length > 0)}
                                                            >
                                                                Send svar
                                                            </Button>
                                                        </form>
                                                    }
                                                    {opgave.opgave.question_type === "foto" &&
                                                        <form className={classes.form} noValidate autoComplete="off" onSubmit={this.sendFotoSvar}>
                                                            <Typography align="left" className={classes.marginTop}>
                                                                <b>Vælg et billede du vil uploade</b>
                                                            </Typography>
                                                            <input type='file' id='image' onChange={this.selectImage} accept="image/*" />
                                                            <Button
                                                                className={classes.button}
                                                                type="submit"
                                                                variant="raised"
                                                                fullWidth
                                                                disabled={!imageFile}
                                                            >
                                                                Send svar
                                                            </Button>
                                                        </form>
                                                    }
                                                    {opgave.opgave.question_type === "multiple_choice" &&
                                                        <form className={classes.form} noValidate autoComplete="off" onSubmit={this.sendTekstSvar}>
                                                            <Typography align="left" className={classes.marginTop}>
                                                                <b>Vælg et svar:</b>
                                                            </Typography>
                                                            <RadioGroup aria-label="tekstSvar" name="tekstSvar" value={tekstSvar} onChange={this.handleChange}>
                                                                {opgave.opgave.answer_options.map(o =>
                                                                    <FormControlLabel value={o} control={<Radio />} label={o} />
                                                                )}
                                                            </RadioGroup>
                                                            <Button
                                                                className={classes.button}
                                                                type="submit"
                                                                variant="raised"
                                                                fullWidth
                                                                disabled={!(tekstSvar.length > 0)}
                                                            >
                                                                Send svar
                                                            </Button>
                                                        </form>
                                                    }
                                                    {opgave.opgave.question_type === "raekkefoelge" &&
                                                        <form className={classes.form} noValidate autoComplete="off" onSubmit={this.sendRaekkefoelgeSvar}>
                                                            <Typography align="left" className={classes.marginTop}>
                                                                <b>Sæt disse i rækkefølge:</b>
                                                            </Typography>
                                                            <Grid container>
                                                                {opgave.opgave.answer_options.map(o =>
                                                                    <Grid item xs={3} align="center" key={o}>
                                                                        <Chip
                                                                            onClick={() => { if (!raekkefoelge.includes(o)) { this.addToRaekkefoelge(o) } }}
                                                                            label={o}
                                                                            className={!raekkefoelge.includes(o) ? classes.chipBlue : classes.chipWhite}
                                                                        />
                                                                    </Grid>
                                                                )}
                                                            </Grid>
                                                            <Typography align="left" className={classes.marginTop}>
                                                                <b>Jeres rækkefølge:</b>
                                                            </Typography>
                                                            <Grid container justify="left">
                                                                {raekkefoelge.map(o =>
                                                                    <Grid item xs={3} align="center" key={o}>
                                                                        <Chip
                                                                            onClick={() => { this.removeFromRaekkefoelge(o) }}
                                                                            label={o}
                                                                            className={classes.chipBlue}
                                                                        />
                                                                    </Grid>
                                                                )}
                                                            </Grid>
                                                            <Button
                                                                className={classes.button}
                                                                type="submit"
                                                                variant="raised"
                                                                fullWidth
                                                                disabled={!(opgave.opgave.answer_options.filter(o => !raekkefoelge.includes(o)).length === 0)}
                                                            >
                                                                Send svar
                                                            </Button>
                                                        </form>
                                                    }
                                                </div>}
                                                {opgave.opgave.is_answered && <div>
                                                    <Typography align="left" className={classes.marginTop}>
                                                        <b>I har besvaret denne opgave.</b>
                                                    </Typography>
                                                    {(opgave.opgave.question_type === "tekst" || opgave.opgave.question_type === "raekkefoelge" || opgave.opgave.question_type === "multiple_choice") &&
                                                        <Typography align="left" className={classes.marginTop}>
                                                            <b>Jeres svar er:</b><br />{opgave.opgave.patrulje_answer}
                                                        </Typography>
                                                    }
                                                    {opgave.opgave.question_type === "foto" &&
                                                        <div>
                                                            <img src={opgave.opgave.patrulje_answer_file} style={{ maxWidth: '100%' }} alt="Patruljens besvarelse" />
                                                        </div>
                                                    }
                                                    {opgave.opgave.retry_possible && <Grid align="right">
                                                        <Button
                                                            className={classes.button}
                                                            type="submit"
                                                            size="small"
                                                            variant="raised"
                                                            onClick={this.fortrydSvar}
                                                        >
                                                            Fortryd svar
                                                        </Button>
                                                    </Grid>
                                                    }
                                                </div>}
                                                {!opgave.opgave.is_open && <div>
                                                    <Typography align="center" className={classes.marginTop}>
                                                        <b>Denne opgave er lukket</b>
                                                    </Typography>
                                                </div>}
                                            </div>
                                        }
                                    </Paper>
                                </Grid>
                            </Grid>
                        </div>}
                </div>}
            </div>
        );
    }
}

Opgave.propTypes = {
    classes: PropTypes.object.isRequired,
};

function mapStateToProps(state) {
    return {
        opgave: state.opgave,
    };
}

export default connect(mapStateToProps)(withRouter(withStyles(styles)(Opgave)));