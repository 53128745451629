import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import { withRouter } from 'react-router-dom';
import { fetchTeltpladserIfNeeded } from './actions/teltpladser';
import { connect } from 'react-redux';
import TextField from 'material-ui/TextField';
import { Typography } from 'material-ui';
import List, { ListItem, ListItemIcon, ListItemText } from 'material-ui/List';
import { Divider } from 'material-ui';
import Avatar from 'material-ui/Avatar';

const styles = theme => ({
    root: {
        // padding: theme.spacing.unit * 2,
        display: 'flex',
        height: '100%',
        width: '100%',
    },
    container: {
        overflow: 'auto',
        flex: 1,
        flexDirection: 'column',
        width: '100%',
    },
    containerInput: {
        padding: theme.spacing.unit * 2,
    },
    containerTable: {
        flex: 1,
        overflow: 'auto',
    },
});

class Teltpladser extends React.Component {
    constructor() {
        super();
        this.state = {
            inputText: '',
            filteredTeltpladser: null,
        };
        this.handleChange = this.handleChange.bind(this);
    }

    fetchTeltpladser() {
        this.props.dispatch(fetchTeltpladserIfNeeded());
    }

    componentDidMount() {
        this.fetchTeltpladser();
    }

    handleChange(evt) {
        const value = evt.target.value;
        if (value === '') {
            this.setState({ inputText: '', filteredTeltpladser: null });
        } else {
            this.setState({
                inputText: value,
                filteredTeltpladser: this.props.teltpladser
                    .filter(
                        t =>
                            t.kredsnavn.search(new RegExp(value, 'i')) > -1 ||
                            t.patruljenavn.search(new RegExp(value, 'i')) > -1 ||
                            (t.plads ? t.plads.search(new RegExp(value, 'i')) > -1 : false),
                    )
                    .sort((a, b) => (a.plads > b.plads) - (a.plads < b.plads)),
            });
        }
    }

    render() {
        const { classes, teltpladser } = this.props;
        return (
            <div className={classes.root}>
                {!teltpladser && <Typography align="center">Kunne ikke indlæse teltpladserne</Typography>}
                {teltpladser && (
                    <div className={classes.container}>
                        <a href="/images/pladsen_full.jpg">
                            <img src="/images/pladsen.jpg" style={{ width: '100%' }} alt="pladsen" />
                        </a>
                        <div className={classes.containerInput}>
                            <TextField
                                name="search"
                                onChange={this.handleChange}
                                fullWidth
                                helperText="Du kan søge efter en patrulje eller en kreds"
                            />
                        </div>
                        {this.state.filteredTeltpladser && (
                            <div className={classes.containerTable}>
                                <List component="nav">
                                    {this.state.filteredTeltpladser.map(t => {
                                        return (
                                            <div key={t.patruljenavn}>
                                                <Divider />
                                                <ListItem>
                                                    <ListItemIcon>
                                                        <Avatar>{t.plads}</Avatar>
                                                    </ListItemIcon>
                                                    <ListItemText primary={t.patruljenavn} secondary={t.kredsnavn} />
                                                </ListItem>
                                            </div>
                                        );
                                    })}
                                </List>
                            </div>
                        )}
                    </div>
                )}
            </div>
        );
    }
}

Teltpladser.propTypes = {
    classes: PropTypes.object.isRequired,
};
function mapStateToProps(state) {
    return {
        teltpladser: state.teltpladser,
    };
}

export default connect(mapStateToProps)(withRouter(withStyles(styles)(Teltpladser)));
