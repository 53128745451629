import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import BottomNavigation, { BottomNavigationAction } from 'material-ui/BottomNavigation';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import DnsIcon from '@material-ui/icons/Dns';
import InfoIcon from '@material-ui/icons/Info';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { withRouter } from 'react-router-dom';

const styles = theme => ({
    root: {
        flexGrow: 1,
        backgroundColor: theme.palette.secondary.main,
        position: 'fixed',
        bottom: 0,
        width: '100%',
        zIndex: 1000,
    },
});

class CustomBottomNavigation extends React.Component {
    state = {
        value: 0,
    };

    componentDidMount() {
        var value;
        var path = this.props.location.pathname.toString();
        if (path.includes('badges')) {
            value = 1;
        } else if (path.includes('opgaver')) {
            value = 2;
        } else if (path.includes('information')) {
            value = 3;
        } else {
            value = 0;
        }
        this.setState({ value: value });
    }

    handleChange = (event, value) => {
        switch (value) {
            case 0:
                this.props.history.push('/');
                break;
            case 1:
                this.props.history.push('/badges');
                break;
            case 2:
                this.props.history.push('/opgaver');
                break;
            case 3:
                this.props.history.push('/information');
                break;
            default:
                this.props.history.push('/');
                break;
        }
        this.setState({ value });
    };

    render() {
        const { classes } = this.props;
        const { value } = this.state;

        return (
            <BottomNavigation value={value} onChange={this.handleChange} showLabels className={classes.root}>
                <BottomNavigationAction label="Poster" icon={<LocationOnIcon />} />
                <BottomNavigationAction label="Badges" icon={<GroupWorkIcon />} />
                <BottomNavigationAction label="Opgaver" icon={<DnsIcon />} />
                <BottomNavigationAction label="Information" icon={<InfoIcon />} />
            </BottomNavigation>
        );
    }
}

CustomBottomNavigation.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(CustomBottomNavigation));
