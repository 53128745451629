export const SET_CENTER = 'SET_CENTER';
export function setCenter(center) {
    return {
        type: SET_CENTER,
        center: center,
    };
}

export const SET_ZOOMLEVEL = 'SET_ZOOMLEVEL';
export function setZoomLevel(zoomLevel) {
    return {
        type: SET_ZOOMLEVEL,
        zoomLevel: zoomLevel,
    };
}

export const SET_POSITION = 'SET_POSITION';
export function setPosition(position) {
    return {
        type: SET_POSITION,
        position: position,
    };
}

export const INVALIDATE_POSITION = 'INVALIDATE_POSITION';
export function invalidatePosition() {
    return {
        type: INVALIDATE_POSITION,
    };
}
