import { invalidateActivation } from './activated';
import { receiveSessionInfo } from './login';
export const REQUEST_TELTPLADSER = 'REQUEST_TELTPLADSER';
function requestTeltpladser() {
    return {
        type: REQUEST_TELTPLADSER,
    };
}

export const RECEIVE_TELTPLADSER = 'RECEIVE_TELTPLADSER';
function receiveTeltpladser(teltpladser) {
    return {
        type: RECEIVE_TELTPLADSER,
        teltpladser: teltpladser,
        receivedAt: Date.now(),
    };
}

function fetchTeltpladser() {
    return dispatch => {
        dispatch(requestTeltpladser());
        return fetch(`${process.env.REACT_APP_API_BASE_URL}/mobileapi/teltpladser`)
            .then(res => {
                return res.json();
            })
            .then(json => {
                if (json.status === 'NO_PATRULJE') {
                    dispatch(invalidateActivation());
                } else {
                    dispatch(receiveTeltpladser(json));
                    dispatch(receiveSessionInfo(json.sessionInfo));
                }
            });
    };
}

export function fetchTeltpladserIfNeeded() {
    return (dispatch, getState) => {
        if (getState().teltpladser) {
            return;
        }
        return dispatch(fetchTeltpladser());
    };
}
