import { invalidateActivation } from './activated';
import { receiveSessionInfo } from './login';
export const REQUEST_OPGAVER = 'REQUEST_OPGAVER';
function requestOpgaver() {
    return {
        type: REQUEST_OPGAVER,
    };
}

export const RECEIVE_OPGAVER = 'RECEIVE_OPGAVER';
export function receiveOpgaver(opgaver) {
    return {
        type: RECEIVE_OPGAVER,
        opgaver: opgaver,
        receivedAt: Date.now(),
    };
}

export const INVALIDATE_OPGAVER = 'INVALIDATE_OPGAVER';
export function invalidateOpgaver() {
    return {
        type: INVALIDATE_OPGAVER,
    };
}

export function fetchOpgaver() {
    return (dispatch, getState) => {
        // grab current state
        const state = getState();

        dispatch(requestOpgaver());
        return fetch(
            `${process.env.REACT_APP_API_BASE_URL}/mobileapi/opgaver?patrulje_token=${state.token}&_=${new Date().getTime()}`,
        )
            .then(res => {
                return res.json();
            })
            .then(json => {
                if (json.status === 'NO_PATRULJE') {
                    dispatch(invalidateActivation());
                } else {
                    dispatch(receiveOpgaver(json.opgaver));
                    dispatch(receiveSessionInfo(json.sessionInfo));
                }
            });
    };
}
