import { RECEIVE_OPGAVE, REQUEST_OPGAVE, ERROR_OPGAVE } from '../actions/opgave';
import { INVALIDATE_ACTIVATION } from '../actions/activated';
import { CLEAR_CACHE } from '../actions/cache';

function reducer(state = null, action) {
    switch (action.type) {
        case REQUEST_OPGAVE:
            return {state: "loading", error: null, opgave: null};
        case RECEIVE_OPGAVE:
            return {state: "loaded", error: null, opgave: action.opgave};
        case ERROR_OPGAVE:
            return {state: "error", error: action.error, opgave: null};
        case INVALIDATE_ACTIVATION:
            return null;
        case CLEAR_CACHE:
            return null;
        default:
            return state;
    }
}

export default reducer;
