import { SET_ZOOMLEVEL, SET_CENTER, SET_POSITION } from '../actions/position';

function reducer(state = { zoomLevel: 16, center: { lat: 55.7176978, lng: 12.3797288 } }, action) {
    switch (action.type) {
        case SET_ZOOMLEVEL:
            return { ...state, zoomLevel: action.zoomLevel };
        case SET_CENTER:
            return { ...state, center: action.center };
        case SET_POSITION:
            return { ...state, position: action.position };
        default:
            return state;
    }
}

export default reducer;
