import { invalidateActivation } from './activated';
import { receiveSessionInfo } from './login';
export const REQUEST_POSTER = 'REQUEST_POSTER';
function requestPoster() {
    return {
        type: REQUEST_POSTER,
    };
}

export const RECEIVE_POSTER = 'RECEIVE_POSTER';
function receivePoster(poster) {
    return {
        type: RECEIVE_POSTER,
        poster: poster,
        receivedAt: Date.now(),
    };
}

export const RECEIVE_POST = 'RECEIVE_POST';
function receivePost(post) {
    return {
        type: RECEIVE_POST,
        post: post,
        receivedAt: Date.now(),
    };
}

export const INVALIDATE_POSTER = 'INVALIDATE_POSTER';
export function invalidatePoster() {
    return {
        type: INVALIDATE_POSTER,
    };
}

export const RECEIVE_FINALEKAPITLER = 'RECEIVE_FINALEKAPITLER';
function receiveFinalekapitler(finalekapitler) {
    return {
        type: RECEIVE_FINALEKAPITLER,
        finalekapitler: finalekapitler,
        receivedAt: Date.now(),
    };
}

export const INVALIDATE_FINALEKAPITLER = 'INVALIDATE_FINALEKAPITLER';
export function invalidateFinalekapitler() {
    return {
        type: INVALIDATE_FINALEKAPITLER,
    };
}

export const POST_CHECKIN = 'POST_CHECKIN';
export function postCheckin(token, lat, lng, accuracy, post_id) {
    return dispatch => {
        // dispatch(requestPoster());
        return fetch(
            `${process.env.REACT_APP_API_BASE_URL}/mobileapi/post_start?post_id=${post_id}&lat=${lat}&lng=${lng}&accuracy=${accuracy}&patrulje_token=${token}&_=${new Date().getTime()}`,
            {
                method: 'POST'
            }
        )
            .then(res => {
                return res.json();
            })
            .then(json => {
                if (json.status === 'NO_PATRULJE') {
                    dispatch(invalidateActivation());
                } else {
                    dispatch(fetchPost(token, lat, lng, accuracy, post_id));
                    dispatch(receiveSessionInfo(json.sessionInfo));
                }
            });
    };
}

export const POST_CHECKOUT = 'POST_CHECKOUT';
export function postCheckout(token, lat, lng, accuracy, post_id) {
    return dispatch => {
        // dispatch(requestPoster());
        return fetch(
            `${process.env.REACT_APP_API_BASE_URL}/mobileapi/post_slut?post_id=${post_id}&lat=${lat}&lng=${lng}&accuracy=${accuracy}&patrulje_token=${token}&_=${new Date().getTime()}`,
            {
                method: 'POST'
            }
        )
            .then(res => {
                return res.json();
            })
            .then(json => {
                if (json.status === 'NO_PATRULJE') {
                    dispatch(invalidateActivation());
                } else if (json.status === 'IS_NOT_IN_QUEUE') {
                    dispatch(fetchPost(token, lat, lng, accuracy, post_id));
                    dispatch(receiveSessionInfo(json.sessionInfo));
                } else {
                    dispatch(fetchPost(token, lat, lng, accuracy, post_id));
                    dispatch(receiveSessionInfo(json.sessionInfo));
                }
            });
    };
}

function fetchPoster(token, lat, lng, accuracy) {
    return dispatch => {
        dispatch(requestPoster());
        return fetch(
            `${process.env.REACT_APP_API_BASE_URL}/mobileapi/poster?lat=${lat}&lng=${lng}&accuracy=${accuracy}&patrulje_token=${token}&_=${new Date().getTime()}`,
        )
            .then(res => {
                return res.json();
            })
            .then(json => {
                if (json.status === 'NO_PATRULJE') {
                    dispatch(invalidateActivation());
                } else {
                    dispatch(receiveFinalekapitler(json.finalekapitler));
                    dispatch(receivePoster(json.poster));
                    dispatch(receiveSessionInfo(json.sessionInfo));
                }
            });
    };
}

export function fetchPost(token, lat, lng, accuracy, post_id) {
    return dispatch => {
        return fetch(
            `${process.env.REACT_APP_API_BASE_URL}/mobileapi/poster/${post_id}?lat=${lat}&lng=${lng}&accuracy=${accuracy}&patrulje_token=${token}&_=${new Date().getTime()}`,
        )
            .then(res => {
                return res.json();
            })
            .then(json => {
                if (json.status === 'NO_PATRULJE') {
                    dispatch(invalidateActivation());
                } else {
                    dispatch(receivePost(json.post));
                    dispatch(receiveSessionInfo(json.sessionInfo));
                }
            });
    };
}

export function fetchPosterIsNeeded(lat, lng, accuracy) {
    return (dispatch, getState) => {
        return dispatch(fetchPoster(getState().token, lat, lng, accuracy));
    };
}
