import { SET_DEFAULTTAB, SET_OPGAVER_TAB } from '../actions/defaultTab';
function reducer(state = null, action) {
    switch (action.type) {
        case SET_DEFAULTTAB:
            return {poster: action.tab, opgaver: state.opgaver ? state.opgaver : 0 };
        case SET_OPGAVER_TAB:
            return {opgaver: action.tab, poster: state.poster ? state.poster : 0 };
        default:
            return state ? state : {opgaver: 0, poster: 0 };
    }
}

export default reducer;
