import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from 'material-ui/styles';
import AppBar from 'material-ui/AppBar';
import Toolbar from 'material-ui/Toolbar';
import { Typography, Chip, Menu, MenuItem, Divider } from 'material-ui';
import { connect } from 'react-redux';
import { invalidateActivation } from './actions/activated';
import { clearCache } from './actions/cache';
import { grey } from 'material-ui/colors';

const styles = theme => ({
    root: {
        flexGrow: 1,
    },
    flex: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    chip: {
        color: 'white',
        backgroundColor: '#326295',
    },
    version: {
        marginLeft: theme.spacing.unit * 2,
        color: grey[400],
        fontSize: 12
    }
});

class MenuAppBar extends React.Component {
    state = {
        anchorEl: null,
    };

    logout = () => {
        this.handleClose();
        this.props.dispatch(invalidateActivation());
    };

    clearLocalCache = () => {
        this.props.dispatch(clearCache());
        this.handleClose();
    };

    handleMenu = event => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ anchorEl: null });
    };

    render() {
        const { classes, activated, sessionInfo } = this.props;
        const { anchorEl } = this.state;
        const open = Boolean(anchorEl);

        return (
            <div className={classes.root}>
                <AppBar position="fixed">
                    <Toolbar>
                        <div>
                            <img alt="VM Logo" src="/new_logo.png" style={{ height: 40 }} />
                        </div>
                        <Typography variant="title" color="inherit" className={classes.flex} align="center">
                            <span>VM Live</span>
                        </Typography>
                        {activated && (
                            <div>
                                <div
                                    aria-owns={open ? 'menu-appbar' : null}
                                    aria-haspopup="true"
                                    onClick={this.handleMenu}
                                    color="inherit"
                                >
                                    <Chip label={sessionInfo.patruljeData ? sessionInfo.patruljeData.pkort : ''} className={classes.chip} />
                                </div>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    anchorOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={open}
                                    onClose={this.handleClose}
                                >
                                    <a href="tel:70605208" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                        <MenuItem onClick={this.handleClose}>Kontakt reception</MenuItem>
                                    </a>
                                    <a href="https://filer.mesterskabet.dk/website/static/VM-LIVE-Userguide-Rev2021-1.pdf" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                                        <MenuItem onClick={this.handleClose}>Brugervejledning</MenuItem>
                                    </a>
                                    <MenuItem onClick={this.clearLocalCache}>Clear cache</MenuItem>
                                    <Divider />
                                    <MenuItem onClick={this.logout}>Log ud</MenuItem>
                                    <Typography className={classes.version}>v1.1.3</Typography>
                                </Menu>
                            </div>
                        )}
                    </Toolbar>
                </AppBar>
            </div>
        );
    }
}

MenuAppBar.propTypes = {
    classes: PropTypes.object.isRequired,
};
function mapStateToProps(state) {
    return {
        token: state.token,
        activated: state.activated,
        sessionInfo: state.sessionInfo,
    };
}

export default connect(mapStateToProps)(withStyles(styles)(MenuAppBar));
