import { invalidateActivation } from './activated';
import { receiveSessionInfo } from './login';
export const REQUEST_BADGES = 'REQUEST_BADGES';
function requestBadges() {
    return {
        type: REQUEST_BADGES,
    };
}

export const RECEIVE_BADGES = 'RECEIVE_BADGES';
export function receiveBadges(badges) {
    return {
        type: RECEIVE_BADGES,
        badges: badges,
        receivedAt: Date.now(),
    };
}

export const INVALIDATE_BADGES = 'INVALIDATE_BADGES';
export function invalidateBadges() {
    return {
        type: INVALIDATE_BADGES,
    };
}

function fetchBadges(state) {
    return dispatch => {
        dispatch(requestBadges());
        return fetch(
            `${process.env.REACT_APP_API_BASE_URL}/mobileapi/badges?patrulje_token=${state.token}&_=${new Date().getTime()}`,
        )
            .then(res => {
                return res.json();
            })
            .then(json => {
                if (json.status === 'NO_PATRULJE') {
                    dispatch(invalidateActivation());
                } else {
                    dispatch(receiveBadges(json.badges));
                    dispatch(receiveSessionInfo(json.sessionInfo));
                }
            });
    };
}

export function fetchBadgesIfNeeded() {
    return (dispatch, getState) => {
        return dispatch(fetchBadges(getState()));
    };
}
