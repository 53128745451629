import { RECEIVE_FINALEKAPITLER } from '../actions/poster';
import { INVALIDATE_ACTIVATION } from '../actions/activated';
import { CLEAR_CACHE } from '../actions/cache';

function reducer(state = null, action) {
    switch (action.type) {
        case RECEIVE_FINALEKAPITLER:
            return action.finalekapitler;
        case INVALIDATE_ACTIVATION:
            return null;
        case CLEAR_CACHE:
            return null;
        default:
            return state;
    }
}

export default reducer;
