export const SET_DEFAULTTAB = 'SET_DEFAULTTAB';
export function setDefaultTab(tab) {
    return {
        type: SET_DEFAULTTAB,
        tab: tab,
    };
}

export const SET_OPGAVER_TAB = 'SET_OPGAVER_TAB';
export function setOpgaverTab(tab) {
    return {
        type: SET_OPGAVER_TAB,
        tab: tab,
    };
}