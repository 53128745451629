import { RECEIVE_BADGES } from '../actions/badges';
function reducer(state = null, action) {
    switch (action.type) {
        case RECEIVE_BADGES:
            return action.badges;
        default:
            return state;
    }
}

export default reducer;
